import React from "react"
import {connect} from "react-redux"
import {Dispatch} from "@reduxjs/toolkit"
import {useNavigate} from "react-router-dom"
import SearchTrip from "../Component/SearchTrip"
import TripType from "../Model/Type/TripType"
import Trip from "../Model/Trip"
import ITrip from "../Interface/Model/ITrip"
import RootState from "../store/actions/States/RootState"
import {findTrip} from "../store/actions/trip"
import {generateRoute, ROUTE_FIND_BIND_DEVICE} from "../Config/Routes"
import {useTranslation} from "react-i18next"
import Layout from "../hoc/Layout";
import Status, {CircleTypes} from "../Component/Status"

interface IState {
    trip: TripType | null,
    loading: boolean,
}

interface IDispatch {
    findTrip: (
        tripNumber: string,
        onError: (message: string) => void,
    ) => void
}

type IProps = IState & IDispatch

const FindBindTripScreen: React.FC<IProps> = (props: IProps) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const loading: boolean = props.loading
    const tripData: TripType | null = props.trip
    const trip: ITrip | null = tripData ? new Trip(tripData) : null

    const findTrip = (data: string): void => {
        props.findTrip(data, (message: string) => alert(t(message)))
    }

    if (trip) {
        navigate(generateRoute(ROUTE_FIND_BIND_DEVICE, {tripNumber: trip.tripNumber}))
    }

    if (trip || loading) {
        return (
            <Layout>
                <Status type={CircleTypes.PROGRESS} text={t("message.loading") + "...."}/>
            </Layout>
        )
    }

    return (
        <SearchTrip
            mainTitle={t("findBindTrip.mainTitle")}
            loading={props.loading}
            onTripSelected={findTrip}
        />
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.tripReducer.loading,
        trip: state.tripReducer.trip,
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        findTrip: (
            tripNumber: string,
            onError: (message: string) => void
        ) => dispatch(findTrip(tripNumber, onError)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindBindTripScreen)
