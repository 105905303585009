import React from "react"
import {connect} from "react-redux"
import {Dispatch} from "@reduxjs/toolkit"
import {Link, useMatch} from "react-router-dom"
import {useTranslation} from "react-i18next"
import PlusIcon from "./Icons/PlusIcon"
import MinusIcon from "./Icons/MinusIcon"
import AccountIcon from "./Icons/AccountIcon"
import {reset} from "../store/actions/root"
import {
    ROUTE_ACCOUNT,
    ROUTE_FIND_BIND_DEVICE,
    ROUTE_FIND_BIND_TRIP,
    ROUTE_FIND_CLOSE_TRIP
} from "../Config/Routes"
import classNames from "classnames"

interface IDispatch {
    reset: () => void
}

type IProps = IDispatch

const Menu: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const routeFindBindTrip = useMatch(ROUTE_FIND_BIND_TRIP)
    const routeFindBindDevice = useMatch({path: ROUTE_FIND_BIND_DEVICE, end: false})
    const routeFindCloseTrip = useMatch({path: ROUTE_FIND_CLOSE_TRIP, end: false})
    const routeAccount = useMatch(ROUTE_ACCOUNT)

    const confirmIsActive: boolean = !!routeFindBindTrip || !!routeFindBindDevice
    const closeIsActive = !!routeFindCloseTrip
    const accountIsActive = !!routeAccount

    return (
        <nav className="bottom-menu">
            <ul className="bottom-menu__list">
                <li className="bottom-menu__item">
                    <Link
                        className={classNames("bottom-menu__link", {"is-active": confirmIsActive})}
                        to={"/trip"}
                        onClick={props.reset}
                    >
                        <PlusIcon/>
                        <span>{t("menu.button.confirm")}</span>
                    </Link>
                </li>
                <li className="bottom-menu__item">
                    <Link
                        className={classNames("bottom-menu__link", {"is-active": closeIsActive})}
                        to={ROUTE_FIND_CLOSE_TRIP}
                        onClick={props.reset}
                    >
                        <MinusIcon/>
                        <span>{t("menu.button.close")}</span>
                    </Link>
                </li>
                <li className="bottom-menu__item">
                    <Link
                        className={classNames("bottom-menu__link", {"is-active": accountIsActive})}
                        to={ROUTE_ACCOUNT}
                        onClick={props.reset}
                    >
                        <AccountIcon/>
                        <span>{t("menu.button.account")}</span>
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        reset: () => dispatch(reset()),
    }
}

export default connect(null, mapDispatchToProps)(Menu)
