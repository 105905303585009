import React, {useState, useEffect} from "react"
import {QrReader} from "@blackbox-vision/react-qr-reader"
import LightIcon from "./Icons/LightIcon"
import Flashlight from "../Service/FlashLight"
import {OnResultFunction} from "@blackbox-vision/react-qr-reader/dist-types/types";

const EXPIRED_IN = 300 * 1000

interface IProps {
    onScanFinished: (data: string) => void,
    onError: () => void,
}

const Scanner: React.FC<IProps> = (props: IProps) => {
    const [data, setData] = useState<string | null>(null)
    const [on, toggle] = useState(false)
    const [reset, setReset] = useState(false)
    const [expiresAt, setExpiresAt] = useState<number | null>(Date.now() + EXPIRED_IN)

    useEffect(() => {
        const t = setInterval(() => {
            if (!expiresAt || expiresAt > Date.now()) {
                return
            }
            props.onError()
            Flashlight.disable()
            setExpiresAt(null)
            clearInterval(t)
        }, 1000)
        return () => clearInterval(t)
    }, [expiresAt])

    useEffect(() => {
        if (!data) {
            return
        }

        Flashlight.disable()
        props.onScanFinished(data)
    }, [data])

    const handler: OnResultFunction = (result, error): void => {
        if (result) {
            setData(result?.getText() || null);
        }

        if (error) {
            console.info(error);
        }
    }

    useEffect(() => {
        if (!reset) {
            return
        }
        setReset(false)
    }, [reset])

    if (reset) {
        return null
    }

    return (
        <QrReader
            ViewFinder={() => {
                return (
                    <div className="qr-scanning">
                        <div className="qr-scanning__code">
                            {
                                <button
                                    className="qr-scanning__button"
                                    type="button"
                                    onClick={() => {
                                        (async () => {
                                            on ? await Flashlight.disable(() => {
                                                setReset(true)
                                            }) : await Flashlight.enable()
                                            toggle(!on)
                                        })()
                                    }}
                                >
                                    <LightIcon/>
                                </button>
                            }
                        </div>
                    </div>
                )
            }}
            constraints={{
                facingMode: 'environment',
            }}
            onResult={handler}
            videoContainerStyle={{
                paddingTop: 0,
            }}
            videoStyle={{
                left: '50%',
                width: 'auto',
            }}
            className="qr-scanning__layout"
        />
    )
}

export default Scanner
