import React from "react"
import AttentionIcon from "../Icons/AttentionIcon"
import {useTranslation} from "react-i18next"

const InfoError: React.FC = () => {
    const {t} = useTranslation()

    return (
        <>
            <div className="device-status-error">
                <AttentionIcon size={40} className="device-status-error__icon"/>
                <div className="device-status-error__text">
                    <h3 className="device-status-error__title">{t("bindDevice.infoError.title")}</h3>
                    <p className="device-status-error__descr">{t("bindDevice.infoError.text")}</p>
                </div>
            </div>
        </>
    )
}

export default InfoError
