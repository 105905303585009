import React, {ChangeEvent, useState} from "react"
import QrIcon from "./Icons/QrIcon"
import LayoutWithFooter from "../hoc/LayoutWithFooter"
import {useTranslation} from "react-i18next"
import Input from "./Elements/Input"
import Scanner from "./Scanner"

interface IProps {
    loading: boolean,
    mainTitle: string,
    scanTitle: string,
    scanButtonText: string,
    inputPlaceholder: string,
    searchButtonText: string,
    onValueEntered: (value: string) => void,
}

const Search: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const [value, setValue] = useState<string>('')
    const [hasError, setHasError] = useState<boolean>(false)
    const [scanOpened, setScanOpened] = useState(false)

    const valueChangedHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setHasError(false)
        setValue(event.target.value)
    }

    const searchClickedHandler = () => {
        if (props.loading) {
            return
        }
        const val:string = value.trim().toUpperCase()
        setValue('')
        if (!val) {
            setHasError(true)
            return
        }
        props.onValueEntered(val)
    }

    const scanFinishedHandler = (tripId: string) => {
        setScanOpened(false)
        props.onValueEntered(tripId)
    }
    const scanFinishedWithErrorHandler = () => {
        setScanOpened(false)
        alert(t("error.qrUnrecognized"))
    }

    if (scanOpened) {
        return (
            <Scanner
                onScanFinished={scanFinishedHandler}
                onError={scanFinishedWithErrorHandler}
            />
        )
    }

    return (
        <LayoutWithFooter title={props.mainTitle}>
            <>
                <div className="bind">
                    <div className="bind__qr">
                        <QrIcon/>
                    </div>
                    <h1 className="bind__title">{props.scanTitle}</h1>
                    <div className="login-form">
                        <div className="login-form__group">
                            <button
                                className="button"
                                type="button"
                                onClick={() => setScanOpened(true)}
                            >
                                {props.scanButtonText}
                            </button>
                        </div>
                        <div className="login-form__or">
                            <span className="login-form__or-text">
                                {t("elements.or")}
                            </span>
                        </div>
                        <div className="login-form__group">
                            <Input
                                value={value}
                                onChange={valueChangedHandler}
                                placeholder={props.inputPlaceholder}
                                hasError={hasError}
                                errorMessage={t("error.fieldRequired")}
                            />
                        </div>
                        <button className="button" onClick={searchClickedHandler}>
                            {props.searchButtonText}
                        </button>
                    </div>
                </div>
            </>
        </LayoutWithFooter>
    )
}

export default Search
