import IAccountState from "../actions/States/IAccountState"
import ActionWithPayload from "../../Interface/ActionWithPayload"
import AccountTypes from "../actions/ActionTypes/AccountTypes"
import {Action} from "redux"
import AuthTypes from "../actions/ActionTypes/AuthTypes"

const initialState: IAccountState = {
    account: null,
    loading: false,
    initialized: false,
}

export default function accountReducer(
    state: IAccountState = initialState,
    action: ActionWithPayload<AccountTypes, IAccountState> | Action<AuthTypes>
): IAccountState {
    switch (action.type) {
        case AccountTypes.ACCOUNT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case AccountTypes.ACCOUNT_LOADED:
            return {
                ...state,
                account: action.payload.account || null,
                loading: false,
                initialized: true,
            }
        case AuthTypes.AUTH_LOGOUT:
            return {
                account: null,
                loading: false,
                initialized: false,
            }
        default:
            return state
    }
}
