import React, {ChangeEvent, useState} from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {Dispatch} from "@reduxjs/toolkit"
import {useTranslation} from "react-i18next"
import LogoIcon from "../Component/Icons/Logo"
import Input from "../Component/Elements/Input"
import RootState from "../store/actions/States/RootState"
import {auth, LANGUAGE_STORAGE_NAME} from "../store/actions/auth"
import classNames from "classnames"
import Layout from "../hoc/Layout"
import {ROUTE_FIND_BIND_TRIP} from "../Config/Routes"
import LangIcon from "../Component/Icons/LangIcon";

const defaultForm = {
    login: {
        value: "",
        hasError: false,
        errorMessage: "",
    },
    password: {
        value: "",
        hasError: false,
        errorMessage: "",
    },
}

interface IState {
    loading: boolean,
}

interface IDispatch {
    auth: (
        email: string,
        password: string,
        onAuthSuccess: () => void,
        onAuthFiled: (message: string) => void,
    ) => void,
}

type IProps = IState & IDispatch

// @todo: Forgot password
const LoginScreen: React.FC<IProps> = (props: IProps) => {
    const {t, i18n} = useTranslation()
    const [formState, setFormState] = useState({
        login: {...defaultForm.login},
        password: {...defaultForm.password},
    })
    const loading: boolean = props.loading

    const onAuthSuccess = () => {
        setFormState({
            login: {...defaultForm.login},
            password: {...defaultForm.password},
        })
    }

    const onAuthFiled = (message: string) => {
        const newFormState = {...formState}
        newFormState.login.hasError = true
        newFormState.login.errorMessage = message
        newFormState.password.hasError = true
        setFormState(newFormState)
    }

    const isValid = (): boolean => {
        const newFormState = {...formState}
        let valid = true
        if (!newFormState.login.value) {
            newFormState.login.hasError = true
            newFormState.login.errorMessage = "error.fieldRequired"
            valid = false
        } else {
            newFormState.login.hasError = false
            newFormState.login.errorMessage = ""
        }
        if (!newFormState.password.value) {
            newFormState.password.hasError = true
            newFormState.password.errorMessage = "error.fieldRequired"
            valid = false
        } else {
            newFormState.password.hasError = false
            newFormState.password.errorMessage = ""
        }
        if (!valid) {
            setFormState(newFormState)
        }

        return valid
    }

    const submitForm = () => {
        if (loading || !isValid()) {
            return
        }
        props.auth(
            formState.login.value,
            formState.password.value,
            onAuthSuccess,
            onAuthFiled
        )
    }

    const changeLangHandler = () => {
        let lng: string = i18n.language || 'en'
        if (lng === 'en') {
            lng = 'ar'
        } else if (lng === 'ar') {
            lng = 'ku'
        } else if (lng === 'ku') {
            lng = 'en'
        }
        i18n.changeLanguage(lng)
        localStorage.setItem(LANGUAGE_STORAGE_NAME, lng)
    }

    return (
        <Layout>
            <div className="login">
                <div className="logo login__logo">
                    <Link className="logo__link" to={ROUTE_FIND_BIND_TRIP}>
                        <LogoIcon size={201} />
                    </Link>
                </div>
                <h1 className="login__title">
                    {t("auth.title")}
                    <Link
                        to="#"
                        onClick={changeLangHandler}
                    >
                        <LangIcon/>
                    </Link>
                </h1>
                <div className="login-form">
                    <div className={classNames("login-form__group", {"has-error": formState.login.hasError})}>
                        <Input
                            placeholder={t("auth.usernamePlaceholder")}
                            value={formState.login.value}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                const newFormState = {...formState}
                                newFormState.login.value = event.target.value
                                newFormState.login.hasError = false
                                newFormState.login.errorMessage = ""
                                setFormState(newFormState)
                            }}
                            hasError={formState.login.hasError}
                            errorMessage={t(formState.login.errorMessage)}
                        />
                    </div>

                    <div className={classNames("login-form__group", {"has-error": formState.password.hasError})}>
                        <Input
                            className="input input--pr-48"
                            isPassword={true}
                            placeholder={t("auth.passwordPlaceholder")}
                            value={formState.password.value}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                const newFormState = {...formState}
                                newFormState.password.value = event.target.value
                                newFormState.password.hasError = false
                                newFormState.password.errorMessage = ""
                                setFormState(newFormState)
                            }}
                            hasError={formState.password.hasError}
                            errorMessage={t(formState.password.errorMessage)}
                        />
                    </div>
                    {/*<div className="login-form__support">*/}
                    {/*    <Link className="login-form__link" to={"#"}>{t("auth.forgotPassword")}</Link>*/}
                    {/*</div>*/}
                    <button className="button" onClick={submitForm}>{t("auth.loginButton")}</button>
                </div>
            </div>
        </Layout>
    )
}
const mapStateToProps = (state: RootState) => {
    return {
        loading: state.authReducer.loading,
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        auth: (
            email: string,
            password: string,
            onSuccess: () => void,
            onError: (message: string) => void,
        ) => dispatch(auth(email, password, onSuccess, onError)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
