import React from "react"
import img from "../../styles/img/sprite.svg"

interface IProps {
    size?: number,
}

const EyeIcon: React.FC<IProps> = (props: IProps) => {
    const width: number = props.size || 23
    const height: number = Math.floor(width * 20 / 23)

    return (
        <svg className="input-group__show-pass-icon" width={width} height={height}>
            <use xlinkHref={img + "#eye"}/>
        </svg>
    )
}

export default EyeIcon
