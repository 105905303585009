import React from "react"
import IDevice from "../Interface/Model/IDevice"
import {useTranslation} from "react-i18next"
import DeviceImage from "./DeviceImage"


type IProps = {
    tripNumber: string,
    verifiedDevice: IDevice,
}

const DeviceInfo: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const tripNumber: string = props.tripNumber
    const verifiedDevice: IDevice | null = props.verifiedDevice

    if (!verifiedDevice) {
        return null
    }

    const data =  [
        [t("deviceInfo.deviceType"), verifiedDevice.deviceType],
        [t("deviceInfo.deviceNo"), verifiedDevice.deviceModel],
        [t("deviceInfo.deviceId"), verifiedDevice.imei],
        [t("deviceInfo.tripId"), tripNumber],
    ]

    return (
        <div className="device">
            <picture className="device__picture">
                <DeviceImage device={verifiedDevice}/>
            </picture>
            <table className="table device__table">
                <tbody>
                {data.map((item: string[], i: number) => {
                    return (
                        <tr key={`line-${i}`}>
                            <td>{item[0]}</td>
                            <td>{item[1]}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

export default DeviceInfo

