import React from "react"
import SuccessIcon from "./Icons/SuccessIcon"
import AttentionIcon from "./Icons/AttentionIcon"
import classNames from "classnames"

interface IProps {
    success: boolean,
    disabled: boolean,
    title: string,
    message: string,
    buttonText: string,
    onClick?: () => void,
}

const DeviceInfoState: React.FC<IProps> = (props: IProps) => {
    return (
        <>
            <div className={classNames({"success": props.success, "error": !props.success})}>
                <div className={classNames({"success__inner": props.success, "error__inner": !props.success})}>
                    {
                        props.success
                            ? <SuccessIcon/>
                            : <AttentionIcon size={63} className="device-status-error__icon_big"/>
                    }
                    <h1 className={classNames({"success__title": props.success, "error__title": !props.success})}>
                        {props.title}
                    </h1>
                    <p className={classNames({"success__descr": props.success, "error__descr": !props.success})}>
                        {props.message}
                    </p>
                </div>
            </div>

            <div className="bottom">
                <button
                    className={classNames("button bottom__button", {
                        "button--green": props.success,
                        "button--red": !props.success,
                    })}
                    type="button"
                    disabled={props.disabled}
                    onClick={() => props.onClick && props.onClick()}
                >
                    {props.buttonText}
                </button>
            </div>
        </>
    )
}

export default DeviceInfoState
