enum CloseTripTypes {
    CLOSE_TRIP_LOADING = "CLOSE_TRIP_LOADING",
    DEVICE_UNBINDING = "DEVICE_UNBINDING",
    TRIP_CLOSING = "TRIP_CLOSING",
    CLOSE_TRIP_LOADED = "CLOSE_TRIP_LOADED",
    DEVICE_UNBIND = "DEVICE_UNBIND",
    TRIP_CLOSED = "TRIP_CLOSED",
    TRIP_CLOSE_ERROR = "TRIP_CLOSE_ERROR",
}

export default CloseTripTypes
