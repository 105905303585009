import React from "react"
import img from "../styles/img/logo.svg"

const Splash: React.FC = () => {
    return (
        <div className="splash-screen">
            <div className="logo">
                <img className="logo__img" src={img} width="220" height="178" alt="logo" />
            </div>
        </div>
    )
}

export default Splash
