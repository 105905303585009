import React from "react"

interface IProps {
    message: string,
    hasError?: boolean,
}

const InputError: React.FC<IProps> = (props: IProps) => {
    return (
        <span className="input-error">
            {props.hasError ? props.message : ""}
        </span>
    )
}

export default InputError
