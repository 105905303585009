import React from "react"
import EyeIcon from "../Icons/EyeIcon"
import classNames from "classnames"

interface IProps {
    status: boolean,
    onChange: (status: boolean) => void,
}

const EyeButton: React.FC<IProps> = (props: IProps) => {

    return (
        <button
            className={classNames("input-group__show-pass", {"is-active": props.status})}
            type="button"
            onTouchStart={() => props.onChange(true)}
            onTouchEnd={() => props.onChange(false)}
        >
            <EyeIcon/>
        </button>
    )
}

export default EyeButton
