import IAccount from "../Interface/Model/IAccount"
import AccountType from "./Type/AccountType"

class Account implements IAccount {
    private readonly _email: string | null
    private readonly _phone: string | null
    private readonly _name: string

    constructor(accountData: AccountType) {
        this._email = accountData.email
        this._phone = accountData.phone
        this._name = accountData.name
    }

    get email(): string | null {
        return this._email
    }

    get phone(): string | null {
        return this._phone
    }

    get name(): string {
        return this._name
    }
}

export default Account
