export default class Flashlight {
    static track;
    static stream;
    static cameras;

    static async accessFlashlight() {
        try {
            const devices = await window.navigator.mediaDevices.enumerateDevices()
            this.cameras = devices.filter((device) => device.kind === 'videoinput')
            if (this.cameras.length === 0) {
                return false
            }

            const camera = this.cameras[this.cameras.length - 1];
            this.stream = await window.navigator.mediaDevices.getUserMedia({
                video: {deviceId: camera.deviceId},
                facingMode: 'environment',
            })
            this.track = this.stream.getVideoTracks()[0];

            this.stream.getVideoTracks()
            return !!this.track.getCapabilities().torch;
        } catch (e) {
            return false
        }
    }

    static async enable() {
        await this.accessFlashlight()
        await this.track.applyConstraints({
            advanced: [{
                fillLightMode: 'on',
                torch: true
            }]
        })
    }


    static async disable(callback) {
        try {
            await this.track.applyConstraints({
                advanced: [{
                    fillLightMode: "off",
                    torch: false
                }]
            })
        } catch (e) {
            // do nothing
        }

        if (this.track.getSettings().torch) {
            this.stream.getVideoTracks().forEach((track) => {
                track.stop()
            })
            callback && callback()
        }
    }
}
