import React from "react"

interface IProps {
    size?: number,
}

const SuccessVIcon: React.FC<IProps> = (props: IProps) => {
    const width = props.size || 24
    const height = Math.floor(width * 20 / 24)

    return (
        <svg
            className="progress-ring__icon"
            viewBox="0 0 24 20"
            fill="none"
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M23.7181 0.566406H21.6703C21.3832 0.566406 21.1107 0.698242 20.9349 0.923828L8.85582 16.2256L3.06383 8.88672C2.97621 8.77547 2.86453 8.68552 2.73717 8.62362C2.60981 8.56172 2.47008 8.52947 2.32848 8.5293H0.280625C0.0843363 8.5293 -0.0240623 8.75488 0.0960549 8.90723L8.12047 19.0732C8.49547 19.5479 9.21617 19.5479 9.5941 19.0732L23.9027 0.941406C24.0228 0.791992 23.9144 0.566406 23.7181 0.566406V0.566406Z" fill="#20BA68"/>
        </svg>
    )
}

export default SuccessVIcon
