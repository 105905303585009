import {Dispatch} from "@reduxjs/toolkit"
import RootTypes from "./ActionTypes/RootTypes"

export const reset = (onComplete?: () => void) => {
    return async (dispatch: Dispatch): Promise<void> => {
        await dispatch(doReset())
        onComplete && onComplete()
    }
}

const doReset = () => {
    return {
        type: RootTypes.ROOT_RESET,
    }
}
