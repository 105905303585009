import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import {LANGUAGE_STORAGE_NAME} from "./store/actions/auth"
import translationCommonEn from "./translations/en/common.json"
import translationCommonAr from "./translations/ar/common.json"
import translationCommonKu from "./translations/ku/common.json"

const resources = {
    en: {
        common: translationCommonEn,
    },
    ar: {
        common: translationCommonAr,
    },
    ku: {
        common: translationCommonKu,
    }
}

let lang = localStorage.getItem(LANGUAGE_STORAGE_NAME)
if (!lang || !Object.keys(resources).includes(lang)) {
    lang = "en"
    localStorage.setItem(LANGUAGE_STORAGE_NAME, lang)
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang,
        defaultNS: "common",
        keySeparator: ".", // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ["br", "strong", "i"],
        }
    })

i18n.changeLanguage(lang)

export default i18n
