import AxiosWrapper from "../../axios/AxiosWrapper"
import IAuthToken from "../../Interface/Model/IAuthToken"
import AuthToken from "../../Model/AuthToken"
import apiClient from "../../Service/ApiClient"
import {TokenResponseType} from "../../Model/Type/Response/TokenResponseType"
import TokenType from "../../Model/Type/TokenType"
import {AxiosError} from "axios"
import {Dispatch} from "@reduxjs/toolkit"
import IAuthState from "./States/IAuthState"
import {Action} from "redux"
import ActionWithPayload from "../../Interface/ActionWithPayload"
import AuthTypes from "./ActionTypes/AuthTypes"
import store from "../store";

export const LANGUAGE_STORAGE_NAME = 'lang'

export const auth = (
    username: string,
    password: string,
    onSuccess: () => void,
    onError: (message: string) => void,
) => {
    return async (dispatch: Dispatch) => {
        dispatch(authLoading())
        try {
            const response: TokenResponseType = await apiClient.auth(username, password)
            dispatch(authSuccess(response.data))
            onSuccess()
        } catch (e: unknown) {
            dispatch(authLogout())
            onError(
                e instanceof AxiosError
                    ? e.response?.data.message || 'Internal Server Error'
                    : 'Internal Server Error'
            )
        }
    }
}

export const refreshToken = (): (dispatch: Dispatch) => Promise<void> => {
    // eslint-disable-next-line
    return async (dispatch: Dispatch | any): Promise<void> => {
        dispatch(authLoading())
        try {
            const response: TokenResponseType = await apiClient.refreshToken()
            dispatch(authSuccess(response.data))
        } catch (e) {
            dispatch(logout())
        }
    }
}

export const logout = (): (dispatch: Dispatch) => Promise<void> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch(authLoading())
        try {
            await apiClient.logout()
        } finally {
            dispatch(authLogout())
        }
    }
}

export const authLogout = (): Action<AuthTypes> => {
    delete AxiosWrapper.defaults.headers.common["Authorization"]
    return {
        type: AuthTypes.AUTH_LOGOUT,
    }
}

export const authLoading = (): Action<AuthTypes> => {
    return {
        type: AuthTypes.AUTH_LOADING,
    }
}

export const authSuccess = (tokenData: TokenType): ActionWithPayload<AuthTypes, IAuthState> => {
    const authToken: IAuthToken = new AuthToken(tokenData.jwt)
    AxiosWrapper.defaults.headers.common["Authorization"] = `Bearer ${authToken.token}`
    setTimeout(() => {
        store.dispatch(refreshToken())
    }, authToken.getExpiresIn())

    return {
        type: AuthTypes.AUTH_SUCCESS,
        payload: {
            token: tokenData,
        }
    }
}
