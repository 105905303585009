import React from "react"
import img from "../../styles/img/sprite.svg"

interface IProps {
    size?: number,
}

const LightIcon: React.FC<IProps> = (props: IProps) => {
    const size: number = props.size || 22

    return (
        <svg className="qr-scanning__button-icon" width={size} height={size}>
            <use xlinkHref={img + "#light"}/>
        </svg>
    )
}

export default LightIcon
