import {AxiosError} from "axios"
import {Action} from "redux"
import {Dispatch} from "@reduxjs/toolkit"
import TripTypes from "./ActionTypes/TripTypes"
import ITripState from "./States/ITripState"
import TripType from "../../Model/Type/TripType"
import CoordsType from "../../Model/Type/CoordsType"
import {TripResponseType} from "../../Model/Type/Response/TripResponseType"
import ActionWithPayload from "../../Interface/ActionWithPayload"
import apiClient from "../../Service/ApiClient"
import {CoordsResponseType} from "../../Model/Type/Response/CoordsResponseType"

export const findTrip = (
    tripNumber: string,
    onError: (message: string) => void
): (dispatch: Dispatch) => Promise<void> => {
    return async (dispatch: Dispatch): Promise<void> => {
        await dispatch(tripLoading())
        try {
            const response: TripResponseType = await apiClient.findTrip(tripNumber)
            await dispatch(tripLoaded(response.data))
        } catch (e: unknown) {
            const errorMsg: string = e instanceof AxiosError
                ? e?.response?.data.message || 'Internal Server Error'
                : 'Internal Server Error'
            await dispatch(tripLoaded(null))
            onError(errorMsg)
        }
    }
}

export const checkDeviceCoords = (
    imei: string
): (dispatch: Dispatch) => Promise<void> => {
    return async (dispatch: Dispatch): Promise<void> => {
        await dispatch(coordsLoading())
        try {
            const response: CoordsResponseType = await apiClient.deviceCheckCoords(imei)
            await dispatch(coordsLoaded(response.data))
        } catch (e: unknown) {
            await dispatch(coordsLoaded(null))
        }
    }
}

export const loadImage = (
    deviceTypeId: number,
    onSuccess: (img: string) => void,
    onError: (message?: string) => void
): (dispatch: Dispatch) => Promise<void> => {
    return async (dispatch: Dispatch): Promise<void> => {
        await dispatch(coordsLoading())
        try {
            const imageSource: string = await apiClient.loadImage(deviceTypeId)
            onSuccess(imageSource)
        } catch (e: unknown) {
            onError()
        }
    }
}

export const releaseTrip = (
    tripId: number,
    onError: (message: string) => void
): (dispatch: Dispatch) => Promise<void> => {
    return async (dispatch: Dispatch): Promise<void> => {
        await dispatch(deviceReleasing())
        try {
            await apiClient.releaseTrip(tripId)
            await dispatch(deviceReleased())
        } catch (e: unknown) {
            const errorMsg: string = e instanceof AxiosError
                ? e?.response?.data.message || 'Internal Server Error'
                : 'Internal Server Error'
            await dispatch(deviceReleaseError())
            onError(errorMsg)
        }
    }
}

export const tripLoading = (): Action<TripTypes> => {
    return {
        type: TripTypes.TRIP_LOADING,
    }
}

export const tripLoaded = (tripData: TripType | null): ActionWithPayload<TripTypes, ITripState> => {
    return {
        type: TripTypes.TRIP_LOADED,
        payload: {
            trip: tripData,
        }
    }
}

export const coordsLoading = (): Action<TripTypes> => {
    return {
        type: TripTypes.COORDS_LOADING,
    }
}

export const resetDeviceCoords = (): Action<TripTypes> => {
    return {
        type: TripTypes.COORDS_RESET,
    }
}

export const coordsLoaded = (coords: CoordsType | null): ActionWithPayload<TripTypes, ITripState> => {
    return {
        type: TripTypes.COORDS_LOADED,
        payload: {coords},
    }
}

export const deviceReleasing = (): Action<TripTypes> => {
    return {
        type: TripTypes.DEVICE_RELEASING,
    }
}

export const deviceReleased = (): Action<TripTypes> => {
    return {
        type: TripTypes.DEVICE_RELEASED,
    }
}

export const deviceReleaseError = (): Action<TripTypes> => {
    return {
        type: TripTypes.DEVICE_RELEASE_ERROR,
    }
}
