import React, {useEffect} from "react"
import {connect} from "react-redux"
import {useNavigate, useParams} from "react-router-dom"
import TripType from "../Model/Type/TripType"
import RootState from "../store/actions/States/RootState"
import SearchDevice from "../Component/SearchDevice"
import {generateRoute, ROUTE_BIND_DEVICE, ROUTE_FIND_BIND_TRIP} from "../Config/Routes"
import {useTranslation} from "react-i18next"
import {Dispatch} from "@reduxjs/toolkit";
import {findTrip} from "../store/actions/trip"
import Layout from "../hoc/Layout"
import Status, {CircleTypes} from "../Component/Status"

interface IState {
    trip: TripType | null,
    loading: boolean,
}

interface IDispatch {
    findTrip: (
        tripNumber: string,
        onError: (message: string) => void
    ) => void,
}

type IProps = IState & IDispatch

const FindBindDeviceScreen: React.FC<IProps> = (props: IProps) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {tripNumber} = useParams()
    const tripData: TripType | null = props.trip
    const loading: boolean = props.loading

    useEffect(() => {
        if (!tripNumber) {
            navigate(ROUTE_FIND_BIND_TRIP)
            return
        }

        if (tripData || loading) {
            return
        }

        props.findTrip(tripNumber, (message: string) => {
            alert(t(message))
            navigate(ROUTE_FIND_BIND_TRIP)
        })
    }, [tripNumber, tripData, loading])

    if (!tripNumber) {
        return null
    }

    if (!tripData || loading) {
        return (
            <Layout>
                <Status type={CircleTypes.PROGRESS} text={t("message.loading") + "...."}/>
            </Layout>
        )
    }

    const imeiReceived = (imei: string): void => {
        navigate(generateRoute(ROUTE_BIND_DEVICE, {tripNumber, imei}))
    }

    return (
        <SearchDevice
            mainTitle={t("findBindDevice.mainTitle")}
            loading={false}
            onDeviceSelected={imeiReceived}
        />
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.tripReducer.loading,
        trip: state.tripReducer.trip,
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        findTrip: (
            tripNumber: string,
            onError: (message: string) => void
        ) => dispatch(findTrip(tripNumber, onError)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindBindDeviceScreen)
