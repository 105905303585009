enum TripTypes {
    TRIP_LOADING = "TRIP_LOADING",
    COORDS_LOADING = "COORDS_LOADING",
    DEVICE_RELEASING = "DEVICE_RELEASING",
    TRIP_LOADED = "TRIP_LOADED",
    COORDS_LOADED = "COORDS_LOADED",
    COORDS_RESET = "COORDS_RESET",
    DEVICE_RELEASED = "DEVICE_RELEASED",
    DEVICE_RELEASE_ERROR = "DEVICE_RELEASE_ERROR",
}

export default TripTypes
