import React from "react"

interface IProps {
    size?: number,
}

const ProgressIcon: React.FC<IProps> = (props: IProps) => {
    const size = props.size || 120

    return (
        <svg
            className="status__loading-icon"
            viewBox="0 0 140 140"
            fill="none"
            width={size}
            height={size}
        >
            <path fillRule="evenodd" clipRule="evenodd" d="M70 130.667C103.505 130.667 130.667 103.505 130.667 70C130.667 36.4947 103.505 9.33333 70 9.33333C36.4947 9.33333 9.33333 36.4947 9.33333 70C9.33333 103.505 36.4947 130.667 70 130.667ZM70 140C108.66 140 140 108.66 140 70C140 31.3401 108.66 0 70 0C31.3401 0 0 31.3401 0 70C0 108.66 31.3401 140 70 140Z" fill="#E5E6E9"/>
            <path d="M74 4.65056C74 4.46025 74.0748 4.31799 74.1617 4.23286C74.2038 4.19162 74.2425 4.17003 74.2706 4.15926C74.2944 4.15015 74.327 4.14195 74.3818 4.1456C85.8939 4.91148 97.0322 8.6848 106.668 15.123C117.521 22.3752 125.981 32.683 130.976 44.7429C135.971 56.8028 137.278 70.0732 134.732 82.876C132.185 95.6787 125.899 107.439 116.669 116.669C107.439 125.899 95.6787 132.185 82.876 134.732C70.0732 137.278 56.8028 135.971 44.7429 130.976C32.683 125.981 22.3752 117.521 15.123 106.668C8.68479 97.0322 4.91147 85.8939 4.14558 74.3818C4.14194 74.3271 4.15013 74.2944 4.15925 74.2706C4.17002 74.2425 4.19162 74.2038 4.23286 74.1617C4.31798 74.0748 4.46025 74 4.65055 74C4.81795 74 4.9918 74.0606 5.15944 74.2243C5.33671 74.3975 5.46806 74.655 5.49088 74.9523C6.33922 86.0039 10.016 96.6826 16.2048 105.945C23.314 116.584 33.4186 124.877 45.2408 129.774C57.063 134.671 70.0718 135.952 82.6221 133.456C95.1725 130.959 106.701 124.797 115.749 115.749C124.797 106.701 130.959 95.1725 133.456 82.6221C135.952 70.0718 134.671 57.063 129.774 45.2408C124.877 33.4186 116.584 23.314 105.945 16.2048C96.6826 10.016 86.0039 6.33922 74.9523 5.49089C74.655 5.46806 74.3975 5.33672 74.2243 5.15945C74.0606 4.99181 74 4.81795 74 4.65056Z" stroke="#367FD3" strokeWidth="8"/>
        </svg>
    )
}

export default ProgressIcon
