import React from "react"
import Search from "../Component/Search"
import {useTranslation} from "react-i18next"

interface IProps {
    mainTitle: string,
    loading: boolean,
    onTripSelected: (tripId: string) => void,
}

const SearchTrip: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    return (
        <Search
            mainTitle={props.mainTitle}
            scanTitle={t("findBindTrip.title")}
            scanButtonText={t("findBindTrip.scanButtonText")}
            searchButtonText={t("findBindTrip.searchButtonText")}
            inputPlaceholder={t("findBindTrip.inputPlaceHolder")}
            onValueEntered={props.onTripSelected}
            loading={props.loading}
        />
    )
}

export default SearchTrip
