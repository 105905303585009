import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios"
import AxiosWrapper from "../axios/AxiosWrapper"
import Config from "../Config/Config"
import {
    ACCOUNT_INFO, DEVICE_CHECK_COORDS, DEVICE_IMAGE, DEVICE_UNBIND,
    FIND_TRIP_FOR_CLOSING,
    FIND_TRIP_FOR_INSTALLATION,
    REFRESH_TOKEN, RELEASE_TRIP,
    SIGN_IN, TRIP_CLOSE, TRIP_DEVICES, TRIP_INFO
} from "../axios/Paths"
import {TokenResponseType} from "../Model/Type/Response/TokenResponseType"
import {AccountResponseType} from "../Model/Type/Response/AccountResponseType"
import {TripResponseType} from "../Model/Type/Response/TripResponseType"
import {CoordsResponseType} from "../Model/Type/Response/CoordsResponseType"
import {Buffer} from "buffer"
import {DevicesInfoResponseType} from "../Model/Type/Response/DevicesInfoResponseType"
import {TripInfoResponseType} from "../Model/Type/Response/TripInfoResponseType"

export type IErrorFallback = (error: AxiosError) => boolean

let fakeCounter = 0
const releaseError = false

const fakeTrip = {
    id: 111111111,
        tripID: "T111111111",
        devices: [{
            imei: "111111111",
            device_model: "FMP100",
            device_type: "GPS",
            device_type_id: 1,
            coords: {
                time: "12:12  12.03.2022",
                lat: "33.3152° N",
                lng: "44.3661° E",
            }
        }]
}

const fakeTripInfo = {
    brand: "ASKAM (FARGO/DESOTO)",
    model: "Hi-Ex",
    driver_licenseID: "8342JGS34KF",
    plate_number: "22222ETA",
    driver_name: "Don Ki Hot",
    cargo_supplier_ownership: "",
    ownership_company: "",
    ownership_companyID: "",
    border_from: "Baghdād",
    border_to: "Al Anbār",
    province_from: "Airport Cargo Terminal",
    province_to: "Al Qa'im",
    cargo_supplier_phone: "+6545674644564654",
    trip_type: "Transit",
}

const fakeDevices = [{
    id: 1,
    device_type: "GPS",
    device_model: "FMP100",
    deviceID: "111111111",
    tripID: fakeTrip.tripID,
    status: 0,
}, {
    id: 2,
    device_type: "GPS",
    device_model: "FMP100",
    deviceID: "222222222",
    tripID: fakeTrip.tripID,
    status: 0,
}, {
    id: 3,
    device_type: "GPS",
    device_model: "FMP100",
    deviceID: "333333333",
    tripID: fakeTrip.tripID,
    status: 0,
}]

class ApiClient {
    async auth(
        username: string,
        password: string,
    ): Promise<TokenResponseType> {
        const apiClient = this.getClient()
        const basicAuth: string = 'Basic ' + Buffer.from(username + ':' + password).toString('base64')
        const response: AxiosResponse<TokenResponseType> = await apiClient.post<TokenResponseType>(
            SIGN_IN,
            undefined,
            {headers: {Authorization: basicAuth}}
        )

        return response.data
    }

    async refreshToken(): Promise<TokenResponseType> {
        const errorFallback: IErrorFallback = () => {
            return false
        }
        const apiClient = this.getClient(errorFallback)
        const response: AxiosResponse<TokenResponseType> = await apiClient.post<TokenResponseType>(REFRESH_TOKEN)
        return response.data
    }

    async loadAccount(userId: number): Promise<AccountResponseType> {
        const apiClient = this.getClient()
        const response: AxiosResponse<AccountResponseType> = await apiClient.get<AccountResponseType>(
            ACCOUNT_INFO(userId)
        )
        return response.data
    }

    async findTrip(tripNumber: string): Promise<TripResponseType> {
        const apiClient = this.getClient()
        try {
            const response: AxiosResponse<TripResponseType> = await apiClient.post<TripResponseType>(
                FIND_TRIP_FOR_INSTALLATION,
                {search: tripNumber}
            )

            return response.data
        } catch (e) {
            if (tripNumber !== fakeTrip.tripID) {
                throw e
            }
            return { data: fakeTrip }
        }
    }

    async deviceCheckCoords(imei: string): Promise<CoordsResponseType> {
        const apiClient = this.getClient()
        try {
            const response: AxiosResponse<CoordsResponseType> = await apiClient.post<CoordsResponseType>(
                DEVICE_CHECK_COORDS,
                {imei}
            )

            return response.data
        } catch (e) {
            fakeCounter++
            if (imei !== fakeTrip.devices[0].imei || fakeCounter < 4) {
                throw e
            }

            fakeCounter = 0
            return {
                data: fakeTrip.devices[0].coords
            }
        }
    }

    async loadImage(deviceTypeId: number): Promise<string> {
        const apiClient = this.getClient()
        const response: AxiosResponse<string> = await apiClient.get<string>(DEVICE_IMAGE(deviceTypeId), {
            responseType: 'arraybuffer',
        })
        return response.data
    }

    async releaseTrip(tripId: number): Promise<void> {
        const apiClient = this.getClient()
        try {
            await apiClient.get(RELEASE_TRIP(tripId))
        } catch (e) {
            if (tripId !== fakeTrip.id || releaseError) {
                throw e
            }

            return
        }
    }

    async findTripToClose(tripNumber: string): Promise<TripResponseType> {
        const apiClient = this.getClient()
        try {
            const response: AxiosResponse<TripResponseType> = await apiClient.post<TripResponseType>(
                FIND_TRIP_FOR_CLOSING,
                {search: tripNumber}
            )

            return response.data
        } catch (e) {
            if (tripNumber !== fakeTrip.tripID) {
                throw e
            }

            return {data: fakeTrip}
        }
    }

    async loadTripInfo(tripId: number): Promise<TripInfoResponseType> {
        const apiClient = this.getClient()
        try {
            const response: AxiosResponse<TripInfoResponseType> = await apiClient.get<TripInfoResponseType>(
                TRIP_INFO(tripId)
            )

            if (!response.data.data && tripId === fakeTrip.id) {
                throw new Error()
            }

            return response.data
        } catch (e) {
            if (tripId !== fakeTrip.id) {
                throw e
            }

            return {data: fakeTripInfo}
        }
    }

    async loadTripDevices(tripId: number): Promise<DevicesInfoResponseType> {
        const apiClient = this.getClient()
        try {
            const response: AxiosResponse<DevicesInfoResponseType> = await apiClient.get<DevicesInfoResponseType>(
                TRIP_DEVICES(tripId)
            )

            if (!response.data.data.length && tripId === fakeTrip.id) {
                throw new Error()
            }

            return response.data
        } catch (e) {
            if (tripId !== fakeTrip.id) {
                throw e
            }

            return {data: fakeDevices}
        }
    }

    async deviceUnbind(tripId: number, imei: string): Promise<void> {
        const apiClient = this.getClient()
        try {
            await apiClient.post<void>(DEVICE_UNBIND(tripId), {imei})
        } catch (e) {
            if (tripId !== fakeTrip.id) {
                throw e
            }
        }
    }

    async closeTrip(tripId: number): Promise<void> {
        const apiClient = this.getClient()
        try {
            await apiClient.get<void>(TRIP_CLOSE(tripId))
        } catch (e) {
            if (tripId !== fakeTrip.id) {
                throw e
            }
        }
    }

    async logout(): Promise<void> {
        const errorFallback: IErrorFallback = () => {
            return false
        }
        const apiClient = this.getClient(errorFallback)
        await apiClient.delete(REFRESH_TOKEN)
    }

    private static processError(error: AxiosError): void {
        console.log(error)
    }

    private static createClient(): AxiosInstance {
        const client = axios.create({
            baseURL: Config.apiBaseUrl,
        })

        client.defaults.headers.common['Authorization'] = AxiosWrapper.defaults.headers.common['Authorization']
        client.defaults.withCredentials = true

        return client
    }

    private getClient(errorFallback?: (error: AxiosError) => boolean) {
        const client = ApiClient.createClient()
        client.interceptors.request.use((config: AxiosRequestConfig) => {
            console.log('Method: ', config.method)
            console.log('Url: ', config.url)
            console.log('Headers: ', config.headers)
            console.log('Data: ', config.data)
            return config
        })
        client.interceptors.response.use((response: AxiosResponse) => {
            console.log('Code: ', response.status)
            console.log('Data: ', response.data)
            return response
        }, async (error) => {

            console.log(error.response.data)
            if (errorFallback && !errorFallback(error)) {

                return Promise.reject(error)
            }

            // if (error.response.status === 401) {
            //     await store.dispatch(refreshToken())
            //
            // }

            ApiClient.processError(error)
            return Promise.reject(error)
        })

        return client
    }
}

const apiClient = new ApiClient()

export default apiClient
