import {Action} from "redux"
import RootTypes from "../actions/ActionTypes/RootTypes"
import ActionWithPayload from "../../Interface/ActionWithPayload"
import ICloseTripState from "../actions/States/ICloseTripState"
import CloseTripTypes from "../actions/ActionTypes/CloseTripTypes"
import TripInfoType from "../../Model/Type/TripInfoType"
import DevicesInfoType from "../../Model/Type/DevicesInfoType"
import TripType from "../../Model/Type/TripType"

const initialState: ICloseTripState = {
    loading: false,
    trip: null,
    tripInfo: null,
    devices: null,
    closed: false,
}

export default function closeTripReducer(
    state: ICloseTripState = initialState,
    action: ActionWithPayload<CloseTripTypes, ICloseTripState> | Action<RootTypes>
): ICloseTripState {
    switch (action.type) {
        case CloseTripTypes.CLOSE_TRIP_LOADING:
            return {
                ...state,
                loading: true,
                trip: null,
                tripInfo: null,
                devices: null,
            }
        case CloseTripTypes.CLOSE_TRIP_LOADED: {
            const trip: TripType | null = action.payload.trip || null
            const tripInfo: TripInfoType | null = action.payload.tripInfo || null
            const devices: DevicesInfoType | null = action.payload.devices || null
            const valid: boolean = !!trip && !!tripInfo && devices !== null
            return {
                ...state,
                loading: false,
                trip: valid ? trip : null,
                tripInfo: valid ? tripInfo : null,
                devices: valid ? devices : null,
            }
        }
        case CloseTripTypes.DEVICE_UNBINDING:
        case CloseTripTypes.TRIP_CLOSING:
            return {
                ...state,
                loading: true,
            }
        case CloseTripTypes.TRIP_CLOSED:
            return {
                ...state,
                loading: false,
                closed: true,
            }
        case CloseTripTypes.TRIP_CLOSE_ERROR:
            return {
                ...state,
                loading: false,
            }
        case CloseTripTypes.DEVICE_UNBIND:
        case RootTypes.ROOT_RESET:
            return {
                loading: false,
                trip: null,
                tripInfo: null,
                devices: null,
                closed: false,
            }
        default:
            return state
    }
}
