import {AxiosError} from "axios"
import {Action} from "redux"
import {Dispatch} from "@reduxjs/toolkit"
import ActionWithPayload from "../../Interface/ActionWithPayload"
import apiClient from "../../Service/ApiClient"
import {TripInfoResponseType} from "../../Model/Type/Response/TripInfoResponseType"
import CloseTripTypes from "./ActionTypes/CloseTripTypes"
import ICloseTripState from "./States/ICloseTripState"
import {DevicesInfoResponseType} from "../../Model/Type/Response/DevicesInfoResponseType"
import TripInfoType from "../../Model/Type/TripInfoType"
import DevicesInfoType from "../../Model/Type/DevicesInfoType"
import {TripResponseType} from "../../Model/Type/Response/TripResponseType"
import TripType from "../../Model/Type/TripType"

export const findTripToClose = (
    tripNumber: string,
    onError: (message: string) => void
): (dispatch: Dispatch) => Promise<void> => {
    return async (dispatch: Dispatch): Promise<void> => {
        await dispatch(tripLoading())
        try {
            const tripResponse: TripResponseType = await apiClient.findTripToClose(tripNumber)
            const tripInfoResponse: TripInfoResponseType = await apiClient.loadTripInfo(tripResponse.data.id)
            const devicesResponse: DevicesInfoResponseType = await apiClient.loadTripDevices(tripResponse.data.id)
            await dispatch(tripLoaded(tripResponse.data, tripInfoResponse.data, devicesResponse.data))
        } catch (e: unknown) {
            const errorMsg: string = e instanceof AxiosError
                ? e?.response?.data.message || 'Internal Server Error'
                : 'Internal Server Error'
            await dispatch(tripLoaded(null, null, null))
            onError(errorMsg)
        }
    }
}

export const deviceUnbind = (
    tripId: number,
    imei: string,
): (dispatch: Dispatch) => Promise<void> => {
    return async (dispatch: Dispatch): Promise<void> => {
        await dispatch(deviceUnbinding())
        try {
            await apiClient.deviceUnbind(tripId, imei)
        } catch (e) {
            // do nothing
        } finally {
            await dispatch(deviceWasUnbind())
        }
    }
}

export const closeTrip = (
    tripId: number,
    onError: (message: string) => void
): (dispatch: Dispatch) => Promise<void> => {
    return async (dispatch: Dispatch): Promise<void> => {
        await dispatch(tripClosing())
        try {
            await apiClient.closeTrip(tripId)
            await dispatch(tripClosed())
        } catch (e: unknown) {
            const errorMsg: string = e instanceof AxiosError
                ? e?.response?.data.message || 'Internal Server Error'
                : 'Internal Server Error'
            await dispatch(tripCloseError())
            onError(errorMsg)
        }
    }
}

export const tripLoading = (): Action<CloseTripTypes> => {
    return {
        type: CloseTripTypes.CLOSE_TRIP_LOADING,
    }
}

export const tripLoaded = (
    trip: TripType | null,
    tripInfo: TripInfoType | null,
    devices: DevicesInfoType | null
): ActionWithPayload<CloseTripTypes, ICloseTripState> => {
    return {
        type: CloseTripTypes.CLOSE_TRIP_LOADED,
        payload: { trip,  tripInfo, devices  }
    }
}

export const deviceUnbinding = (): Action<CloseTripTypes> => {
    return {
        type: CloseTripTypes.DEVICE_UNBINDING,
    }
}

export const deviceWasUnbind = (): Action<CloseTripTypes> => {
    return {
        type: CloseTripTypes.DEVICE_UNBIND,
    }
}

export const tripClosing = (): Action<CloseTripTypes> => {
    return {
        type: CloseTripTypes.TRIP_CLOSING,
    }
}

export const tripClosed = (): Action<CloseTripTypes> => {
    return {
        type: CloseTripTypes.TRIP_CLOSED,
    }
}

export const tripCloseError = (): Action<CloseTripTypes> => {
    return {
        type: CloseTripTypes.TRIP_CLOSE_ERROR,
    }
}

