import React from "react"
import ITrip from "../Interface/Model/ITrip"
import IDevice from "../Interface/Model/IDevice"
import BindStateEnum from "../Model/Enum/BindStateEnum"
import CoordsType from "../Model/Type/CoordsType"
import InfoLoading from "./DeviceInfo/InfoLoading"
import InfoSuccess from "./DeviceInfo/InfoSuccess"
import InfoError from "./DeviceInfo/InfoError"
import DeviceInfo from "./DeviceInfo"
import {useTranslation} from "react-i18next"

type IProps = {
    trip: ITrip,
    verifiedDevice: IDevice | null,
    coords: CoordsType | null,
    state: BindStateEnum,
    onClickUpdate: () => void,
    onClickRelease: () => void,
}

const DeviceBind: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const state: BindStateEnum = props.state
    const trip: ITrip = props.trip
    const verifiedDevice: IDevice | null = props.verifiedDevice
    const coords: CoordsType | null = props.coords
    const stopStates: BindStateEnum[] = [
        BindStateEnum.BINDING_IN_PROGRESS,
        BindStateEnum.BINDING_SUCCESS,
        BindStateEnum.BINDING_ERROR,
    ]

    if (!verifiedDevice || !stopStates.includes(state)) {
        return null
    }

    const inProgress: boolean = state === BindStateEnum.BINDING_IN_PROGRESS
    const success: boolean = state === BindStateEnum.BINDING_SUCCESS
    const error: boolean = state === BindStateEnum.BINDING_ERROR

    return (
        <>
            <DeviceInfo
                tripNumber={trip.tripNumber}
                verifiedDevice={verifiedDevice}
            />
            <div className="device-status">
                {inProgress && <InfoLoading/>}
                {success && <InfoSuccess coords={coords}/>}
                {error && <InfoError/>}
                <button
                    className="button button--outline-blue device-status__button"
                    type="button"
                    disabled={inProgress}
                    onClick={() => !inProgress && props.onClickUpdate()}
                >
                    {t("bindDevice.updateInfoButtonText")}
                </button>
            </div>
            <div className="bottom">
                <button
                    className="button button--green bottom__button"
                    type="button"
                    disabled={!success}
                    onClick={() => success && props.onClickRelease()}
                >
                    {t("bindDevice.confirmButtonText")}
                </button>
            </div>
        </>
    )
}

export default DeviceBind

