import React from "react"
import img from "../../styles/img/sprite.svg"

interface IProps {
    size?: number,
}

const SuccessIcon: React.FC<IProps> = (props: IProps) => {
    const size = props.size || 86

    return (
        <svg className="success__icon" width={size} height={size}>
            <use xlinkHref={img + "#success"}/>
        </svg>
    )
}

export default SuccessIcon
