import React from "react"

interface IProps {
    size?: number,
}

const ErrorXIcon: React.FC<IProps> = (props: IProps) => {
    const size: number = props.size || 24

    return (
        <svg
            className="progress-ring__icon"
            viewBox="0 0 24 24"
            fill="none"
            width={size}
            height={size}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M13.8548 12L23.1822 1.20207C23.3385 1.02262 23.2071 0.75 22.9655 0.75H20.1299C19.9629 0.75 19.803 0.822469 19.6929 0.946702L12 9.85353L4.30713 0.946702C4.20053 0.822469 4.04063 0.75 3.87007 0.75H1.03455C0.792924 0.75 0.661452 1.02262 0.817797 1.20207L10.1452 12L0.817797 22.7979C0.782774 22.8379 0.760306 22.8868 0.753059 22.9389C0.745811 22.9909 0.754089 23.0439 0.776911 23.0915C0.799732 23.1391 0.836138 23.1793 0.881806 23.2074C0.927474 23.2355 0.980486 23.2503 1.03455 23.25H3.87007C4.03708 23.25 4.19698 23.1775 4.30713 23.0533L12 14.1465L19.6929 23.0533C19.7995 23.1775 19.9594 23.25 20.1299 23.25H22.9655C23.2071 23.25 23.3385 22.9774 23.1822 22.7979L13.8548 12Z" fill="#E74B53"/>
        </svg>
    )
}

export default ErrorXIcon
