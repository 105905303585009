import React from "react"
import img from "../../styles/img/logo.svg"

interface IProps {
    size?: number,
}

const LogoIcon: React.FC<IProps> = (props: IProps) => {
    const width: number = props.size || 220
    const height: number = Math.floor(width * 178 / 220)

    return (
        <img className="logo__img" src={img} width={width} height={height} alt="logo"/>
    )
}

export default LogoIcon
