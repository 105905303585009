import React, {useEffect} from "react"
import {connect} from "react-redux"
import {useNavigate, useParams} from "react-router-dom"
import RootState from "../store/actions/States/RootState"
import DevicesInfoType from "../Model/Type/DevicesInfoType"
import DeviceInfoType from "../Model/Type/DeviceInfoType"
import TripInfoType from "../Model/Type/TripInfoType"
import {closeTrip, findTripToClose} from "../store/actions/closeTrip"
import {Dispatch} from "@reduxjs/toolkit"
import LayoutWithTitle from "../hoc/LayoutWithTitle"
import Status, {CircleTypes} from "../Component/Status"
import {ROUTE_CLOSE_TRIP_ERROR, ROUTE_CLOSE_TRIP_SUCCESS, ROUTE_FIND_CLOSE_TRIP} from "../Config/Routes"
import TripType from "../Model/Type/TripType"
import Layout from "../hoc/Layout"
import {useTranslation} from "react-i18next"
import ITrip from "../Interface/Model/ITrip";
import Trip from "../Model/Trip";
import DevicesList from "../Component/DevicesList";

interface IState {
    loading: boolean,
    trip: TripType | null,
    tripInfo: TripInfoType | null,
    devices: DevicesInfoType | null,
    closed: boolean,
}

interface IDispatch {
    findTripToClose: (
        tripNumber: string,
        onError: (message: string) => void,
    ) => void,
    closeTrip: (
        tripId: number,
        onError: (message: string) => void
    ) => void,
}

type IProps = IState & IDispatch

const CloseTripScreen: React.FC<IProps> = (props: IProps) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {tripNumber} = useParams()
    const loading: boolean = props.loading
    const closed: boolean = props.closed
    const tripData: TripType | null = props.trip
    const tripInfo: TripInfoType | null = props.tripInfo
    const devices: DevicesInfoType = props.devices || []

    useEffect(() => {
        if (!tripNumber) {
            navigate(ROUTE_FIND_CLOSE_TRIP)
            return
        }

        if ((tripData && tripInfo) || loading) {
            return
        }

        props.findTripToClose(tripNumber, (message: string) => {
            alert(t(message))
            navigate(ROUTE_FIND_CLOSE_TRIP)
        })
    }, [tripNumber, tripData, tripInfo, loading])

    useEffect(() => {
        if (!closed || loading) {
            return
        }
        navigate(ROUTE_CLOSE_TRIP_SUCCESS)
    }, [closed])

    if (!tripNumber) {
        return null
    }

    if (!tripData || !tripInfo || loading) {
        return (
            <Layout>
                <Status type={CircleTypes.PROGRESS} text={t("message.loading") + "...."}/>
            </Layout>
        )
    }

    const trip: ITrip = new Trip(tripData)
    const tripTableData = [
        [t("tripWithDevice.tripType"), tripInfo.trip_type],
        [t("tripWithDevice.brand"), tripInfo.brand],
        [t("tripWithDevice.model"), tripInfo.model],
        [t("tripWithDevice.plateNumber"), tripInfo.plate_number],
        [t("tripWithDevice.driverName"), tripInfo.driver_name],
        [t("tripWithDevice.driverLicense"), tripInfo.driver_licenseID],
        [t("tripWithDevice.fromBorderGw"), tripInfo.province_from],
        [t("tripWithDevice.fromBorderGwItIsComing"), tripInfo.border_from],
        [t("tripWithDevice.toBorderGw"), tripInfo.province_to],
        [t("tripWithDevice.toBorderGwItIsComing"), tripInfo.border_to],
        [t("tripWithDevice.ownershipCompany"), tripInfo.ownership_company],
        [t("tripWithDevice.ownershipCompanyId"), tripInfo.ownership_companyID],
        [t("tripWithDevice.cargoSupplierOwnership"), tripInfo.cargo_supplier_ownership],
        [t("tripWithDevice.cargoSupplierPhone"), tripInfo.cargo_supplier_phone],
    ]

    const activeDevices = devices.filter((deviceInfo: DeviceInfoType) => {
        return deviceInfo.status === 1
    }) || []

    const closedDevices = devices.filter((deviceInfo: DeviceInfoType) => {
        return deviceInfo.status === 0
    }) || []

    const closeTripHandler = () => {
        if (activeDevices.length) {
            return
        }
        props.closeTrip(tripData.id, (message: string) => {
            alert(message)
            navigate(ROUTE_CLOSE_TRIP_ERROR)
        })
    }

    return (
        <LayoutWithTitle title="Close the trip">
            <>
                <div className="info">
                    <h1 className="info__title">{t("tripWithDevice.tripTitle")}</h1>
                    <div className="info__table-wrap">
                        <table className="table info__table">
                            <tbody>
                            {tripTableData.map((tripInfoItem: string[], i: number) => {
                                return (
                                    <tr key={`trip-${i}`}>
                                        <td style={{paddingRight: 10}}>{tripInfoItem[0]}</td>
                                        <td style={{
                                            paddingLeft: 10,
                                            direction: "ltr",
                                            textAlign: "right"
                                        }}>{tripInfoItem[1]}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    <DevicesList
                        trip={trip}
                        devices={[...activeDevices, ...closedDevices]}
                    />
                </div>
                <div className="bottom">
                    <button
                        className="button button--green bottom__button"
                        type="button" disabled={!!activeDevices.length}
                        onClick={closeTripHandler}
                    >{t("tripWithDevice.closeTripButtonText")}</button>
                </div>
            </>
        </LayoutWithTitle>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.closeTripReducer.loading,
        trip: state.closeTripReducer.trip,
        tripInfo: state.closeTripReducer.tripInfo,
        devices: state.closeTripReducer.devices,
        closed: state.closeTripReducer.closed,
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        findTripToClose: (
            tripNumber: string,
            onError: (message: string) => void
        ) => dispatch(findTripToClose(tripNumber, onError)),
        closeTrip: (
            tripId: number,
            onError: (message: string) => void
        ) => dispatch(closeTrip(tripId, onError)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseTripScreen)
