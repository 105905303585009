import React from "react"
import img from "../../styles/img/sprite.svg"

interface IProps {
    size?: number,
}

const QrIcon: React.FC<IProps> = (props: IProps) => {
    const size: number = props.size || 24

    return (
        <svg className="info-item__qr-button-icon" width={size} height={size}>
            <use xlinkHref={img + "#qr"}/>
        </svg>
    )
}

export default QrIcon
