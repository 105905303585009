import apiClient from "../../Service/ApiClient"
import {AccountResponseType} from "../../Model/Type/Response/AccountResponseType"
import AccountType from "../../Model/Type/AccountType"
import {Dispatch} from "@reduxjs/toolkit"
import {Action} from "redux"
import ActionWithPayload from "../../Interface/ActionWithPayload"
import IAccountState from "./States/IAccountState"
import AccountTypes from "./ActionTypes/AccountTypes"

export const loadAccount = (
    userId: number,
    onError: () => void
) => {
    return async (dispatch: Dispatch) => {
        await dispatch(accountLoading())
        try {
            const response: AccountResponseType = await apiClient.loadAccount(userId)
            await dispatch(accountLoaded(response.data))
            !response.data && onError()
        } catch (e) {
            await dispatch(accountLoaded(null))
            onError()
        }
    }
}

export const accountLoading = (): Action<AccountTypes> => {
    return {
        type: AccountTypes.ACCOUNT_LOADING,
    }
}

export const accountLoaded = (
    accountData: AccountType | null
): ActionWithPayload<AccountTypes, IAccountState> => {
    return {
        type: AccountTypes.ACCOUNT_LOADED,
        payload: {
            account: accountData
        },
    }
}
