import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {useNavigate, useParams} from "react-router-dom"
import TripType from "../Model/Type/TripType"
import RootState from "../store/actions/States/RootState"
import SearchDevice from "../Component/SearchDevice"
import {
    generateRoute,
    ROUTE_FIND_CLOSE_TRIP,
    ROUTE_TRIP_WITH_DEVICES,
    ROUTE_UNBIND_DEVICE
} from "../Config/Routes"
import {useTranslation} from "react-i18next"
import {Dispatch} from "@reduxjs/toolkit"
import {findTripToClose} from "../store/actions/closeTrip"
import Layout from "../hoc/Layout"
import Status, {CircleTypes} from "../Component/Status"
import LayoutWithTitle from "../hoc/LayoutWithTitle"
import DeviceInfoState from "../Component/DeviceInfoState"

interface IState {
    loading: boolean,
    trip: TripType | null,
}

interface IDispatch {
    findTripToClose: (
        tripNumber: string,
        onError: (message: string) => void,
    ) => void,
}

type IProps = IState & IDispatch

const FindUnbindDeviceScreen: React.FC<IProps> = (props: IProps) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {tripNumber, imei} = useParams()
    const [enteredImei, setEnteredImei] = useState<string>('')
    const loading: boolean = props.loading
    const tripData: TripType | null = props.trip

    useEffect(() => {
        if (!tripNumber) {
            navigate(ROUTE_FIND_CLOSE_TRIP)
            return
        }

        if (!imei) {
            navigate(generateRoute(ROUTE_TRIP_WITH_DEVICES, {tripNumber}))
            return
        }

        if (tripData || loading) {
            return
        }

        props.findTripToClose(tripNumber, (message: string) => {
            alert(t(message))
            navigate(ROUTE_FIND_CLOSE_TRIP)
        })
    }, [tripNumber, tripData, imei, loading])

    useEffect(() => {
        if (!tripNumber || loading || imei !== enteredImei) {
            return
        }

        navigate(generateRoute(ROUTE_UNBIND_DEVICE, {
            tripNumber: tripNumber,
            imei: enteredImei
        }))
    }, [tripNumber, loading, imei, enteredImei])

    if (!tripNumber || !imei) {
        return null
    }

    if (!tripData || loading || imei === enteredImei) {
        return (
            <Layout>
                <Status type={CircleTypes.PROGRESS} text={t("message.loading") + "...."}/>
            </Layout>
        )
    }

    const imeiReceived = (enteredImei: string): void => {
        setEnteredImei(enteredImei)
    }

    if (enteredImei && imei !== enteredImei) {
        return (
            <LayoutWithTitle
                title={t("unbindDevice.mainTitle")}
            >
                <DeviceInfoState
                    success={false}
                    disabled={true}
                    title={t("unbindDevice.doesNotMatch.title")}
                    message={t("unbindDevice.doesNotMatch.text")}
                    buttonText={t("unbindDevice.doesNotMatch.buttonText")}
                    onClick={() => {
                        setEnteredImei('')
                    }}
                />
            </LayoutWithTitle>
        )
    }

    return (
        <SearchDevice
            mainTitle={`${t("findUnbindDevice.mainTitle")} (${tripData.tripID})`}
            loading={false}
            onDeviceSelected={imeiReceived}
        />
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.closeTripReducer.loading,
        trip: state.closeTripReducer.trip,
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        findTripToClose: (
            tripNumber: string,
            onError: (message: string) => void
        ) => dispatch(findTripToClose(tripNumber, onError)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindUnbindDeviceScreen)
