import IDevice from "../Interface/Model/IDevice"
import DeviceType from "./Type/DeviceType"

class Device implements IDevice {
    private readonly _imei: string
    private readonly _deviceModel: string
    private readonly _deviceType: string
    private readonly _deviceTypeId: number

    constructor(deviceData: DeviceType) {
        this._imei = deviceData.imei
        this._deviceModel = deviceData.device_model
        this._deviceType = deviceData.device_type
        this._deviceTypeId = deviceData.device_type_id
    }

    get imei(): string {
        return this._imei
    }

    get deviceModel(): string {
        return this._deviceModel
    }

    get deviceType(): string {
        return this._deviceType
    }

    get deviceTypeId(): number {
        return this._deviceTypeId
    }
}

export default Device
