import React, {ReactElement} from "react"

interface IProps {
    children: ReactElement,
}

const Layout: React.FC<IProps> = (props: IProps) => {
    return (
        <div className="main">
            {props.children}
        </div>
    )
}

export default Layout
