import React from "react"
import {useTranslation} from "react-i18next"

const InfoLoading: React.FC = () => {
    const {t} = useTranslation()

    return (
        <>
            <h1 className="device-status__title">{t("bindDevice.infoLoading.title")}</h1>
            <p className="device-status__info">{t("bindDevice.infoLoading.text")}...</p>
        </>
    )
}

export default InfoLoading
