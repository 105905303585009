import React from "react"
import IDevice from "../Interface/Model/IDevice"
import DeviceInfo from "./DeviceInfo"
import ITrip from "../Interface/Model/ITrip"

type IProps = {
    trip: ITrip,
    device: IDevice,
    buttonText: string,
    onClickUnbind: () => void,
}

const DeviceUnbind: React.FC<IProps> = (props: IProps) => {
    const trip: ITrip = props.trip
    const verifiedDevice: IDevice = props.device

    return (
        <>
            <DeviceInfo
                tripNumber={trip.tripNumber}
                verifiedDevice={verifiedDevice}
            />
            <div className="bottom">
                <button
                    className="button bottom__button"
                    type="button"
                    onClick={() => {
                        confirm("Are you sure you want to unbind device?") && props.onClickUnbind()
                    }}
                >
                    {props.buttonText}
                </button>
            </div>
        </>
    )
}

export default DeviceUnbind

