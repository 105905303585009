import {combineReducers} from "redux"
import authReducer from "./auth"
import accountReducer from "./account"
import tripReducer from "./trip"
import closeTripReducer from "./closeTrip"

export default combineReducers({
    authReducer,
    accountReducer,
    tripReducer,
    closeTripReducer,
})
