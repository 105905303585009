declare global {
    interface Window {
        env: {[key: string]: string | number | boolean | null}
    }
}
const Config = {
    apiBaseUrl: (window.env?.REACT_APP_BASE_API_URL || process.env.REACT_APP_BASE_API_URL || 'https://api.itms-staging.uruk.gov.iq').toString(),
    checkCoordsRetryTimeout: +(window.env?.REACT_APP_CHECK_COORDS_RETRY_TIMEOUT || process.env.REACT_APP_CHECK_COORDS_RETRY_TIMEOUT || 5),
    checkCoordsRetryLimit: +(window.env?.REACT_APP_CHECK_COORDS_RETRY_LIMIT || process.env.REACT_APP_CHECK_COORDS_RETRY_LIMIT || 12),
}

export default Config
