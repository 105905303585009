import React from "react"
import {connect} from "react-redux"
import {Dispatch} from "@reduxjs/toolkit"
import {useNavigate} from "react-router-dom"
import SearchTrip from "../Component/SearchTrip"
import RootState from "../store/actions/States/RootState"
import {findTripToClose} from "../store/actions/closeTrip"
import Status, {CircleTypes} from "../Component/Status"
import {generateRoute, ROUTE_TRIP_WITH_DEVICES} from "../Config/Routes"
import TripType from "../Model/Type/TripType"
import Layout from "../hoc/Layout"
import {useTranslation} from "react-i18next"
import ITrip from "../Interface/Model/ITrip"
import Trip from "../Model/Trip"

interface IState {
    trip: TripType | null,
    loading: boolean,
}

interface IDispatch {
    findTripToClose: (
        tripNumber: string,
        onError: (message: string) => void,
    ) => void,
}

type IProps = IState & IDispatch

const FindCloseTripScreen: React.FC<IProps> = (props: IProps) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const loading: boolean = props.loading
    const tripData: TripType | null = props.trip
    const trip: ITrip | null = tripData ? new Trip(tripData) : null


    const findTripToClose = (data: string): void => {
        props.findTripToClose(data, (message: string) => alert(t(message)))
    }

    if (trip) {
        navigate(generateRoute(ROUTE_TRIP_WITH_DEVICES, {tripNumber: trip.tripNumber}))
    }

    if (trip || loading) {
        return (
            <Layout>
                <Status type={CircleTypes.PROGRESS} text={t("message.loading") + "...."}/>
            </Layout>
        )
    }

    return (
        <SearchTrip
            mainTitle={t("findCloseTrip.mainTitle")}
            loading={props.loading}
            onTripSelected={findTripToClose}
        />
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.closeTripReducer.loading,
        trip: state.closeTripReducer.trip,
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        findTripToClose: (
            tripNumber: string,
            onError: (message: string) => void
        ) => dispatch(findTripToClose(tripNumber, onError)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindCloseTripScreen)
