import React, {useCallback, useEffect, useState} from "react"
import {connect} from "react-redux"
import Layout from "../hoc/Layout"
import ITrip from "../Interface/Model/ITrip"
import IDevice from "../Interface/Model/IDevice"
import Trip from "../Model/Trip"
import TripType from "../Model/Type/TripType"
import BindStateEnum from "../Model/Enum/BindStateEnum"
import CoordsType from "../Model/Type/CoordsType"
import {useNavigate, useParams} from "react-router-dom"
import {checkDeviceCoords, findTrip, releaseTrip, resetDeviceCoords} from "../store/actions/trip"
import LayoutWithTitle from "../hoc/LayoutWithTitle"
import DeviceInfoState from "../Component/DeviceInfoState"
import Status, {CircleTypes} from "../Component/Status"
import RootState from "../store/actions/States/RootState"
import {Dispatch} from "@reduxjs/toolkit"
import DeviceBind from "../Component/DeviceBind"
import {
    ROUTE_BIND_DEVICE_SUCCESS, ROUTE_BIND_DEVICE_ERROR,
    ROUTE_FIND_BIND_TRIP
} from "../Config/Routes"
import {useTranslation} from "react-i18next"
import Config from "../Config/Config"

interface IState {
    loading: boolean,
    trip: TripType | null,
    coords: CoordsType | null,
    released: boolean,
}

interface IDispatch {
    findTrip: (
        tripNumber: string,
        onError: (message: string) => void
    ) => void,
    checkDeviceCoords: (imei: string) => void,
    resetDeviceCoords: () => void,
    releaseTrip: (
        tripId: number,
        onError: (message: string) => void
    ) => void,
}

type IProps = IState & IDispatch

const BindDeviceScreen: React.FC<IProps> = (props: IProps) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [state, setState] = useState<BindStateEnum>(BindStateEnum.INIT)
    const {tripNumber, imei} = useParams()
    const loading: boolean = props.loading
    const tripData: TripType | null = props.trip
    const coords: CoordsType | null = props.coords
    const released: boolean = props.released
    const trip: ITrip | null = tripData ? new Trip(tripData) : null
    const device: IDevice | null = trip && imei ? trip.getDeviceByImei(imei) : null
    const [checkCoordsCounter, setCheckCoordsCounter] = useState(0)

    const checkCords = useCallback(() => {
        const allowedStatuses: BindStateEnum[] = [
            BindStateEnum.INIT,
            BindStateEnum.BINDING_IN_PROGRESS,
        ]
        if (loading || !device || coords || !allowedStatuses.includes(state)) {
            return
        }

        if (checkCoordsCounter >= Config.checkCoordsRetryLimit) {
            return
        }
        props.checkDeviceCoords(device.imei)
        setState(BindStateEnum.BINDING_IN_PROGRESS)
        setCheckCoordsCounter(current => current + 1)
    }, [loading, device, coords, state])

    useEffect(() => {
        if (!tripNumber || !imei) {
            navigate(ROUTE_FIND_BIND_TRIP)
            return
        }

        if (tripData || loading) {
            return
        }

        props.findTrip(tripNumber, (message: string) => {
            alert(t(message))
            navigate(ROUTE_FIND_BIND_TRIP)
        })
    }, [tripData, loading, tripNumber])

    useEffect(() => {
        const t = setTimeout(() => {
            checkCords()
        }, Config.checkCoordsRetryTimeout * 1000)

        if (state === BindStateEnum.INIT) {
            checkCords()
        }

        return () => {clearTimeout(t)}
    })

    useEffect(() => {
        if (loading) {
            return
        }
        const releaseInProgress = state === BindStateEnum.RELEASE
        const limitNotReached = checkCoordsCounter < Config.checkCoordsRetryLimit
        if (coords && !releaseInProgress) {
            setState(BindStateEnum.BINDING_SUCCESS)
            return
        }

        if (releaseInProgress || limitNotReached) {
            return
        }

        setState(BindStateEnum.BINDING_ERROR)
    }, [loading, state, coords, checkCoordsCounter])

    useEffect(() => {
        if (!released || loading) {
            return
        }
        navigate(ROUTE_BIND_DEVICE_SUCCESS)
    }, [loading, released])

    const handleUpdate = () => {
        props.resetDeviceCoords()
        setState(BindStateEnum.INIT)
        setCheckCoordsCounter(0)
    }

    const handleRelease = () => {
        if (!trip || !coords || loading || state !== BindStateEnum.BINDING_SUCCESS) {
            return
        }
        setState(BindStateEnum.RELEASE)
        props.releaseTrip(trip.id, (message? :string) => {
            alert(message)
            navigate(ROUTE_BIND_DEVICE_ERROR)
        })
    }

    if (!tripNumber || !imei) {
        return null
    }

    if (!trip || (loading && state === BindStateEnum.RELEASE)) {
        return (
            <Layout>
                <Status type={CircleTypes.PROGRESS} text={t("message.loading") + "...."}/>
            </Layout>
        )
    }

    if (trip && !device) {
        return (
            <LayoutWithTitle
                title={t("bindDevice.mainTitle")}
            >
                <DeviceInfoState
                    success={false}
                    disabled={true}
                    title={t("bindDevice.doesNotMatch.title")}
                    message={t("bindDevice.doesNotMatch.text")}
                    buttonText={t("bindDevice.doesNotMatch.buttonText")}
                />
            </LayoutWithTitle>
        )
    }

    return (
        <Layout>
            <DeviceBind
                state={state}
                trip={trip}
                verifiedDevice={device}
                coords={coords}
                onClickUpdate={handleUpdate}
                onClickRelease={handleRelease}
            />
        </Layout>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        loading: state.tripReducer.loading,
        trip: state.tripReducer.trip,
        coords: state.tripReducer.coords,
        released: state.tripReducer.released,
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        findTrip: (
            tripNumber: string,
            onError: (message: string) => void
        ) => dispatch(findTrip(tripNumber, onError)),
        checkDeviceCoords: (imei: string) => dispatch(checkDeviceCoords(imei)),
        resetDeviceCoords: () => dispatch(resetDeviceCoords()),
        releaseTrip: (
            tripId: number,
            onError: (message: string) => void
        ) => dispatch(releaseTrip(tripId, onError)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BindDeviceScreen)
