import React from "react"
import {connect} from "react-redux"
import LayoutWithFooter from "../hoc/LayoutWithFooter"
import IAccount from "../Interface/Model/IAccount"
import AccountType from "../Model/Type/AccountType"
import Account from "../Model/Account"
import UserLogoIcon from "../Component/Icons/UserLogoIcon"
import LangIcon from "../Component/Icons/LangIcon"
import ArrowRightIcon from "../Component/Icons/ArrowRightIcon"
import RootState from "../store/actions/States/RootState"
import {Dispatch} from "@reduxjs/toolkit"
import {logout} from "../store/actions/auth"
import {useTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import {ROUTE_LANGUAGE} from "../Config/Routes";

interface IState {
    account: AccountType | null,
}

interface IDispatch {
    logout: () => void,
}

type IProps = IState & IDispatch

const AccountScreen: React.FC<IProps> = (props: IProps) => {
    const account:IAccount | null = props.account ? new Account(props.account) : null
    const {t} = useTranslation()

    return (
        <LayoutWithFooter title={t("account.mainTitle")}>
            <div className="account">
                <h1 className="account__title">{t("account.mainTitle")}</h1>
                <div className="account-item">
                    <div className="account-item__pic">
                        <UserLogoIcon/>
                    </div>
                    <div className="account-item__text">
                        <h3 className="account-item__name">{account?.name || '...'}</h3>
                        <p className="account-item__phone">{account?.phone || ""}</p>
                    </div>
                </div>
                <div className="lang">
                    <Link className="lang__link" to={ROUTE_LANGUAGE}>
                        <LangIcon/>
                        <span className="lang__title">{t("account.language")}</span>
                        <ArrowRightIcon/>
                    </Link>
                </div>
                <button
                    className="button button--grey account__logout"
                    type="button"
                    onClick={() => props.logout()}
                >
                    {t("account.logoutButton")}
                </button>
            </div>
        </LayoutWithFooter>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        account: state.accountReducer.account,
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        logout: () => dispatch(logout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountScreen)
