import ITrip from "../Interface/Model/ITrip"
import TripType from "./Type/TripType"
import IDevice from "../Interface/Model/IDevice"
import DeviceType from "./Type/DeviceType"
import Device from "./Device"

class Trip implements ITrip {
    private readonly _id: number
    private readonly _tripNumber: string
    private _devices: IDevice[]

    constructor(tripData: TripType) {
        this._id = tripData.id
        this._tripNumber = tripData.tripID
        this._devices = tripData.devices.map((deviceData: DeviceType): IDevice => {
            return new Device(deviceData)
        })
    }

    get id(): number {
        return this._id
    }

    get tripNumber(): string {
        return this._tripNumber
    }

    get devices(): IDevice[] {
        return this._devices
    }

    getDeviceByImei(imei: string): IDevice | null {
        const matchedDevices: IDevice[] = this._devices.filter((device: IDevice): boolean => {
            return device.imei.toLowerCase() === imei.toLowerCase()
        })

        return matchedDevices.length ? matchedDevices[0] : null
    }
}

export default Trip
