export const ROUTE_FIND_BIND_TRIP = "/"
export const ROUTE_LOGIN = "/login"
export const ROUTE_FIND_BIND_DEVICE = "/trip/:tripNumber/bind"
export const ROUTE_BIND_DEVICE = "/trip/:tripNumber/bind/:imei"
export const ROUTE_BIND_DEVICE_SUCCESS = "/trip/success"
export const ROUTE_BIND_DEVICE_ERROR = "/trip/error"
export const ROUTE_FIND_CLOSE_TRIP = "/close-trip"
export const ROUTE_TRIP_WITH_DEVICES = "/close-trip/:tripNumber"
export const ROUTE_FIND_UNBIND_DEVICE = "/close-trip/:tripNumber/verify/:imei"
export const ROUTE_UNBIND_DEVICE = "/close-trip/:tripNumber/unbind/:imei"
export const ROUTE_CLOSE_TRIP_SUCCESS = "/close-trip/success"
export const ROUTE_CLOSE_TRIP_ERROR = "/close-trip/error"
export const ROUTE_ACCOUNT = "/account"
export const ROUTE_LANGUAGE = "/account/language"

export const generateRoute = (route: string, params: {[key: string]: string | number}) => {
    Object.keys(params).map((paramName: string) => {
        const reg = new RegExp(`/:${paramName}(/|$)`)
        route = route.replace(reg, "/" + params[paramName]+"$1")
    })

    return route
}

