import React from "react"
import deviceInfoType from "../Model/Type/DeviceInfoType"
import QrSymbolIcon from "./Icons/QrSymbolIcon"
import classNames from "classnames"
import {useNavigate} from "react-router-dom"
import {generateRoute, ROUTE_FIND_UNBIND_DEVICE} from "../Config/Routes"
import {useTranslation} from "react-i18next"
import ITrip from "../Interface/Model/ITrip";

interface IProps {
    trip: ITrip,
    deviceInfo: deviceInfoType,
}

const DeviceInfoItem: React.FC<IProps> = (props: IProps) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    return (
        <div className="info-item">
            <div className="info-item__header">
                <h3 className="info-item__title">
                    {props.deviceInfo.device_type}
                </h3>
                <p className="info-item__number">
                    {props.deviceInfo.device_model}
                </p>
                <span
                    className={classNames(
                        "info-item__status",
                        {"info-item__status--black": !props.deviceInfo.status}
                    )}
                >
                    {props.deviceInfo.status === 1
                        ? `${t("deviceInfo.statusActive")}`
                        : `${t("deviceInfo.statusClosed")}`
                    }
                </span>
            </div>
            <div className="info-item__row">
                <div>
                    <h4 className="info-item__sub-title">
                        {t("deviceInfo.deviceId")}: {props.deviceInfo.deviceID}
                    </h4>
                    <h4 className="info-item__sub-title">
                        {t("deviceInfo.tripId")}: {props.deviceInfo.tripID}
                    </h4>
                </div>
                {
                    props.deviceInfo.status === 1
                    ?
                        <div className="info-item__col-3">
                            <button className="info-item__qr-button" onClick={() => {
                                navigate(generateRoute(ROUTE_FIND_UNBIND_DEVICE, {
                                    tripNumber: props.trip.tripNumber,
                                    imei: props.deviceInfo.deviceID
                                }))
                            }}>
                                <QrSymbolIcon />
                            </button>
                        </div>
                    : null
                }
            </div>
        </div>
    )
}

export default DeviceInfoItem

