import React from "react"
import img  from "../../styles/img/sprite.svg"

interface IProps {
    size?: number,
    className?: string,
}

const AttentionIcon: React.FC<IProps> = (props: IProps) => {
    const size: number = props.size || 72

    return (
        <svg className={props.className} width={size} height={size}>
            <use xlinkHref={img + "#error"}/>
        </svg>
    )
}

export default AttentionIcon
