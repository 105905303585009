import React from "react"
import CoordsType from "../../Model/Type/CoordsType"

interface IProps {
    coords: CoordsType | null,
}

const InfoSuccess: React.FC<IProps> = (props: IProps) => {
    const time: string = props.coords?.time || ''
    const position: string = [props.coords?.lat || '', props.coords?.lng || ''].join(', ')

    return (
        <>
            <p className="device-status__descr">
                {time} <br/>
                <strong>{position}</strong>
            </p>
        </>
    )
}

export default InfoSuccess
