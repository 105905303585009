import React, {ReactElement} from "react"
import Menu from "../Component/Menu"
import LayoutWithTitle from "./LayoutWithTitle"

interface IProps {
    children: ReactElement,
    title?: string,
}

const LayoutWithFooter: React.FC<IProps> = (props: IProps) => {
    return (
        <LayoutWithTitle>
            <>
                {props.children}
                <Menu/>
            </>
        </LayoutWithTitle>
    )
}

export default LayoutWithFooter
