import React, {ReactElement} from "react"
import Layout from "./Layout"
import {Link, useNavigate} from "react-router-dom"

interface IProps {
    children: ReactElement,
    title?: string,
}

const LayoutWithTitle: React.FC<IProps> = (props: IProps) => {
    const navigate = useNavigate()
    return (
        <Layout>
            <>
                {
                    props.title
                    ?
                        <div className="back">
                            <Link
                                className="back__link"
                                to={'..'}
                                onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                    e.preventDefault()
                                    navigate(-1)
                                }}
                            >
                                {props.title}
                            </Link>
                        </div>
                    : null
                }
                {props.children}
            </>
        </Layout>
    )
}

export default LayoutWithTitle
