import React, {useEffect} from "react"
import {useNavigate} from "react-router-dom"
import DeviceInfoState from "../Component/DeviceInfoState"
import {ROUTE_FIND_CLOSE_TRIP} from "../Config/Routes"
import Layout from "../hoc/Layout"
import {useTranslation} from "react-i18next"
import {Dispatch} from "@reduxjs/toolkit"
import {reset} from "../store/actions/root"
import {connect} from "react-redux"

interface IDispatch {
    reset: () => void,
}

type IProps = IDispatch

const CloseTripSuccessScreen: React.FC<IProps> = (props: IProps) => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => {
        props.reset()
    }, [])

    return (
        <Layout>
            <DeviceInfoState
                success={true}
                disabled={false}
                title={t("tripClosed.success.title")}
                message={t("tripClosed.success.message")}
                buttonText={t("tripClosed.success.buttonText")}
                onClick={() => {
                    navigate(ROUTE_FIND_CLOSE_TRIP)
                }}
            />
        </Layout>
    )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        reset: () => dispatch(reset()),
    }
}

export default connect(null, mapDispatchToProps)(CloseTripSuccessScreen)
