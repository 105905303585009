import IAuthState from "../actions/States/IAuthState"
import AuthTypes from "../actions/ActionTypes/AuthTypes"
import ActionWithPayload from "../../Interface/ActionWithPayload"

const initialState: IAuthState = {
    token: null,
    loading: false,
    initialized: false,
}

export default function authReducer(
    state: IAuthState = initialState,
    action: ActionWithPayload<AuthTypes, IAuthState>
): IAuthState {
    switch (action.type) {
        case AuthTypes.AUTH_LOADING:
            return {
                ...state,
                loading: true,
            }
        case AuthTypes.AUTH_SUCCESS:
            return {
                token: action.payload.token || null,
                loading: false,
                initialized: true,
            }
        case AuthTypes.AUTH_LOGOUT:
            return {
                token: null,
                loading: false,
                initialized: true,
            }
        default:
            return state
    }
}
