import jwt_decode from 'jwt-decode'
import IAuthToken from "../Interface/Model/IAuthToken"

type JWTTokenData = {
    exp: number,
    lng: string,
    rid: number,
    scope: string[],
    uid: number,
}

class AuthToken implements IAuthToken {
    private readonly _token: string
    private readonly _expiresAt: Date
    private readonly _userId: number
    private readonly _lang: string

    constructor(token: string) {
        this._token = token
        const payload = jwt_decode(token) as JWTTokenData
        this._expiresAt = new Date(payload.exp * 1000)
        this._userId = payload.uid
        this._lang = payload.lng || 'en'
    }

    isExpired = (): boolean => {
        return this._expiresAt <= new Date()
    }

    getExpiresIn = (): number => {
        return this._expiresAt.getTime() - new Date().getTime()
    }

    get token(): string {
        return this._token
    }

    get userId(): number {
        return this._userId
    }

    get lang(): string {
        return this._lang
    }
}

export default AuthToken
