import React, {useEffect} from "react"
import {connect} from "react-redux"
import Layout from "../hoc/Layout"
import {useNavigate, useParams} from "react-router-dom"
import LayoutWithTitle from "../hoc/LayoutWithTitle"
import DeviceInfoState from "../Component/DeviceInfoState"
import Status, {CircleTypes} from "../Component/Status"
import RootState from "../store/actions/States/RootState"
import {Dispatch} from "@reduxjs/toolkit"
import DeviceUnbind from "../Component/DeviceUnbind"
import DevicesInfoType from "../Model/Type/DevicesInfoType"
import {deviceUnbind, findTripToClose} from "../store/actions/closeTrip"
import {generateRoute, ROUTE_FIND_CLOSE_TRIP, ROUTE_TRIP_WITH_DEVICES} from "../Config/Routes"
import TripType from "../Model/Type/TripType"
import Trip from "../Model/Trip"
import IDevice from "../Interface/Model/IDevice"
import ITrip from "../Interface/Model/ITrip"
import {useTranslation} from "react-i18next"
import DeviceInfoType from "../Model/Type/DeviceInfoType"

interface IState {
    loading: boolean,
    trip: TripType | null,
    devices: DevicesInfoType | null,
}

interface IDispatch {
    deviceUnbind: (
        tripId: number,
        imei: string,
    ) => void,
    findTripToClose: (
        tripNumber: string,
        onError: (message: string) => void,
    ) => void,
}

type IProps = IState & IDispatch

const UnbindDeviceScreen: React.FC<IProps> = (props: IProps) => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {tripNumber, imei} = useParams()
    const loading: boolean = props.loading
    const tripData: TripType | null = props.trip
    const devices: DevicesInfoType | null = props.devices
    const trip: ITrip | null = tripData ? new Trip(tripData) : null
    const matchedDevice: IDevice | null = trip && imei
        ? trip.getDeviceByImei(imei)
        : null
    const device: IDevice | null = matchedDevice
        && devices
        && devices.filter((device: DeviceInfoType) => {
            return matchedDevice.imei === device.deviceID && device.status === 1
        }).length
        ? matchedDevice
        : null

    useEffect(() => {
        if (!tripNumber) {
            navigate(ROUTE_FIND_CLOSE_TRIP)
            return
        }

        if (!imei) {
            navigate(generateRoute(ROUTE_TRIP_WITH_DEVICES, {tripNumber}))
            return
        }

        if (trip || loading) {
            return
        }

        props.findTripToClose(tripNumber, (message: string) => {
            alert(t(message))
            navigate(ROUTE_FIND_CLOSE_TRIP)
        })
    }, [tripNumber, tripData, imei, loading])

    if (!tripNumber || !imei) {
        return null
    }

    if (!trip || loading) {
        return (
            <Layout>
                <Status type={CircleTypes.PROGRESS} text={t("message.loading") + "...."}/>
            </Layout>
        )
    }

    if (!device) {
        return (
            <LayoutWithTitle title={t("unbindDevice.mainTitle")}>
                <DeviceInfoState
                    success={false}
                    disabled={true}
                    title={t("unbindDevice.doesNotMatch.title")}
                    message={t("unbindDevice.doesNotMatch.message")}
                    buttonText={t("unbindDevice.doesNotMatch.buttonText")}
                />
            </LayoutWithTitle>
        )
    }

    const handleUnbind = () => {
        props.deviceUnbind(trip.id, imei)
        navigate(generateRoute(ROUTE_TRIP_WITH_DEVICES, {tripNumber}))
    }

    return (
        <Layout>
            <DeviceUnbind
                trip={trip}
                device={device}
                onClickUnbind={handleUnbind}
                buttonText={t("unbindDevice.unbindButtonText")}
            />
        </Layout>
    )
}
const mapStateToProps = (state: RootState) => {
    return {
        loading: state.closeTripReducer.loading,
        trip: state.closeTripReducer.trip,
        devices: state.closeTripReducer.devices,
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        findTripToClose: (
            tripNumber: string,
            onError: (message: string) => void,
        ) => dispatch(findTripToClose(tripNumber, onError)),
        deviceUnbind: (
            tripId: number,
            imei: string,
        ) => dispatch(deviceUnbind(tripId, imei)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnbindDeviceScreen)
