import React, {useEffect} from "react"
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom"
import "./styles/sass/main.sass"
import Splash from "./Component/Splash"
import Auth from "./Component/Security/Auth"
import {connect} from "react-redux"
import RootState from "./store/actions/States/RootState"
import AccountScreen from "./Screen/AccountScreen"
import LoginScreen from "./Screen/LoginScreen"
import FindBindTripScreen from "./Screen/FindBindTripScreen"
import FindBindDeviceScreen from "./Screen/FindBindDeviceScreen"
import BindDeviceScreen from "./Screen/BindDeviceScreen"
import BindDeviceConfirmedScreen from "./Screen/BindDeviceSuccessScreen"
import BindDeviceErrorScreen from "./Screen/BindDeviceErrorScreen"
import FindCloseTripScreen from "./Screen/FindCloseTripScreen"
import TripWithDevicesScreen from "./Screen/TripWithDevicesScreen"
import FindUnbindDeviceScreen from "./Screen/FindUnbindDeviceScreen"
import UnbindDeviceScreen from "./Screen/UnbindDeviceScreen"
import CloseTripSuccessScreen from "./Screen/CloseTripSuccessScreen"
import CloseTripErrorScreen from "./Screen/CloseTripErrorScreen"
import {
    ROUTE_ACCOUNT,
    ROUTE_FIND_BIND_DEVICE,
    ROUTE_BIND_DEVICE,
    ROUTE_FIND_CLOSE_TRIP,
    ROUTE_FIND_BIND_TRIP,
    ROUTE_CLOSE_TRIP_SUCCESS,
    ROUTE_LOGIN,
    ROUTE_TRIP_WITH_DEVICES,
    ROUTE_FIND_UNBIND_DEVICE,
    ROUTE_UNBIND_DEVICE,
    ROUTE_BIND_DEVICE_SUCCESS,
    ROUTE_BIND_DEVICE_ERROR,
    ROUTE_CLOSE_TRIP_ERROR,
    ROUTE_LANGUAGE
} from "./Config/Routes"
import LanguageScreen from "./Screen/LanguageScreen"
import {useTranslation} from "react-i18next"

interface IStateProps {
    isInitialized: boolean,
    isAuthorized: boolean,
}
type IProps = IStateProps

const App: React.FC<IProps> = (props: IProps) => {
    const isReady: boolean = props.isInitialized
    const isAuth: boolean = props.isAuthorized
    const {i18n} = useTranslation()

    useEffect(() => {
        const rtl: string = i18n.language === 'en' ? 'ltr' : 'rtl'
        document.getElementsByTagName('html')[0].setAttribute("dir", rtl);
        document.getElementsByTagName('html')[0].setAttribute("lang", i18n.language);
    }, [i18n.language])

    if (!isReady) {
        return (
            <>
                <Splash/>
                <Auth/>
            </>
        )
    }

    return (
        <>
            <BrowserRouter>
                <Routes>
                    {
                        !isAuth && <Route index={true} path={ROUTE_LOGIN} element={<LoginScreen/>}/>
                    }
                    {
                        isAuth
                            ?
                            <>
                                <Route
                                    index={true}
                                    path={ROUTE_FIND_BIND_TRIP}
                                    element={<FindBindTripScreen/>}
                                />
                                <Route
                                    path={ROUTE_FIND_BIND_DEVICE}
                                    element={<FindBindDeviceScreen/>}
                                />
                                <Route
                                    path={ROUTE_BIND_DEVICE}
                                    element={<BindDeviceScreen/>}
                                />
                                <Route
                                    path={ROUTE_BIND_DEVICE_SUCCESS}
                                    element={<BindDeviceConfirmedScreen/>}
                                />
                                <Route
                                    path={ROUTE_BIND_DEVICE_ERROR}
                                    element={<BindDeviceErrorScreen/>}
                                />
                                <Route
                                    path={ROUTE_FIND_CLOSE_TRIP}
                                    element={<FindCloseTripScreen/>}
                                />
                                <Route
                                    path={ROUTE_TRIP_WITH_DEVICES}
                                    element={<TripWithDevicesScreen/>}
                                />
                                <Route
                                    path={ROUTE_FIND_UNBIND_DEVICE}
                                    element={<FindUnbindDeviceScreen/>}
                                />
                                <Route
                                    path={ROUTE_UNBIND_DEVICE}
                                    element={<UnbindDeviceScreen/>}
                                />
                                <Route
                                    path={ROUTE_CLOSE_TRIP_SUCCESS}
                                    element={<CloseTripSuccessScreen/>}
                                />
                                <Route
                                    path={ROUTE_CLOSE_TRIP_ERROR}
                                    element={<CloseTripErrorScreen/>}
                                />
                                <Route
                                    path={ROUTE_ACCOUNT}
                                    element={<AccountScreen/>}
                                />
                                <Route
                                    path={ROUTE_LANGUAGE}
                                    element={<LanguageScreen/>}
                                />
                            </>
                            : null
                    }
                    <Route path="*" element={<Navigate to={isAuth ? "/" : ROUTE_LOGIN} />}/>
                </Routes>
            </BrowserRouter>
            <Auth/>
        </>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        isInitialized: state.authReducer.initialized,
        isAuthorized: !!state.authReducer.token,
    }
}

export default connect(mapStateToProps)(App)
