import React from "react"
import SuccessVIcon from "./Icons/SuccessVIcon"
import ErrorXIcon from "./Icons/ErrorXIcon"
import classNames from "classnames"
import ProgressIcon from "./Icons/ProgressIcon"

export enum CircleTypes {
    PROGRESS = 'progress',
    SUCCESS = 'success',
    ERROR = 'error',
}

interface IProps {
    type: CircleTypes,
    text: string,
}

const Status: React.FC<IProps> = (props: IProps) => {
    return (
        <div className="status">
            {
                props.type !== CircleTypes.PROGRESS
                ?
                    <div className={classNames("progress-ring", {
                        "progress-ring--green": props.type === CircleTypes.SUCCESS,
                        "progress-ring--red": props.type === CircleTypes.ERROR,
                    })} data-percent="75">
                        <svg className="progress-ring__svg">
                            <circle className="progress-ring__circle" cx="86" cy="86" r="70"/>
                            <circle className="progress-ring__circle progress-ring__circle-fill" cx="86" cy="86" r="70"/>
                        </svg>
                        {props.type === CircleTypes.SUCCESS && <SuccessVIcon/>}
                        {props.type === CircleTypes.ERROR && <ErrorXIcon />}
                    </div>
                : null
            }
            {
                props.type === CircleTypes.PROGRESS
                ?
                    <div className="status__loading">
                        <ProgressIcon/>
                    </div>
                : null
            }

            <h1 className={classNames("status__title", {
                "status__title--green": props.type === CircleTypes.SUCCESS,
                "status__title--red": props.type === CircleTypes.ERROR,
            })}>{props.text}</h1>
        </div>
    )
}

export default Status
