import {Action} from "redux"
import ITripState from "../actions/States/ITripState"
import TripTypes from "../actions/ActionTypes/TripTypes"
import RootTypes from "../actions/ActionTypes/RootTypes"
import ActionWithPayload from "../../Interface/ActionWithPayload"

const initialState: ITripState = {
    loading: false,
    trip: null,
    coords: null,
    released: false,
}

export default function tripReducer(
    state: ITripState = initialState,
    action: ActionWithPayload<TripTypes, ITripState> | Action<RootTypes>
): ITripState {
    switch (action.type) {
        case TripTypes.TRIP_LOADING:
            return {
                ...state,
                loading: true,
                trip: null,
                coords: null,
            }
        case TripTypes.TRIP_LOADED:
            return {
                ...state,
                loading: false,
                trip: action.payload.trip || null,
            }
        case TripTypes.COORDS_LOADING:
            return {
                ...state,
                loading: true,
                coords: null,
            }
        case TripTypes.COORDS_LOADED:
            return {
                ...state,
                loading: false,
                coords: state.trip && action.payload.coords
                    ? action.payload.coords
                    : null,
            }
        case TripTypes.COORDS_RESET:
            return {
                ...state,
                loading: false,
                coords: null,
            }
        case TripTypes.DEVICE_RELEASING:
            return {
                ...state,
                loading: true,
            }
        case TripTypes.DEVICE_RELEASE_ERROR:
            return {
                ...state,
                loading: false,
                coords: null,
            }
        case TripTypes.DEVICE_RELEASED:
            return {
                ...state,
                loading: false,
                released: true,
            }
        case RootTypes.ROOT_RESET:
            return {
                loading: false,
                trip: null,
                coords: null,
                released: false,
            }
        default:
            return state
    }
}
