import React from "react"
import img from "../../styles/img/qr.svg"

interface IProps {
    size?: number,
}

const QrIcon: React.FC<IProps> = (props: IProps) => {
    const width: number = props.size || 179
    const height: number = Math.floor(width * 184 / 179)

    return (
        <img className="bind__qr-img" width={width} height={height} src={img}/>
    )
}

export default QrIcon
