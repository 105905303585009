import React from "react"
import Search from "../Component/Search"
import {useTranslation} from "react-i18next"

interface IProps {
    mainTitle: string,
    loading: boolean,
    onDeviceSelected: (tripId: string) => void,
}

const SearchDevice: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    return (
        <Search
            mainTitle={props.mainTitle}
            scanTitle={t("findBindDevice.title")}
            scanButtonText={t("findBindDevice.scanButtonText")}
            searchButtonText={t("findBindDevice.searchButtonText")}
            inputPlaceholder={t("findBindDevice.inputPlaceHolder")}
            onValueEntered={props.onDeviceSelected}
            loading={props.loading}
        />
    )
}

export default SearchDevice
