import React, {useEffect} from "react"
import {connect} from "react-redux"
import {refreshToken} from "../../store/actions/auth"
import {loadAccount} from "../../store/actions/account"
import AuthToken from "../../Model/AuthToken"
import TokenType from "../../Model/Type/TokenType"
import {Dispatch} from "@reduxjs/toolkit"
import RootState from "../../store/actions/States/RootState"
import {useTranslation} from "react-i18next"

interface IStateProps {
    token: TokenType | null,
    isLoading: boolean,
    isInitialized: boolean,
    isAccountLoading: boolean,
    isAccountInitialized: boolean,
}

interface IDispatchProps {
    refreshToken: () => void,
    loadAccount: (userId: number, onError: () => void) => void,
}

type IProps = IStateProps & IDispatchProps

const Auth: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()
    const {
        token, isLoading, isInitialized, refreshToken,
        isAccountLoading, isAccountInitialized, loadAccount
    } = props
    const shouldLoadAccount: boolean = !isLoading && !isAccountLoading && !!token && !isAccountInitialized

    useEffect(() => {
        if (isInitialized || isLoading) {
            return
        }
        refreshToken()
    }, [isInitialized, isLoading])

    useEffect(() => {
        if (!shouldLoadAccount || !token) {
            return
        }

        const authToken: AuthToken = new AuthToken(token.jwt)
        loadAccount(authToken.userId, () => {
            alert(t("error.canNotLoadUserData"))
        })
    }, [shouldLoadAccount, token])

    return null
}

const mapStateToProps = (state: RootState) => {
    return {
        token: state.authReducer.token,
        isLoading: state.authReducer.loading,
        isInitialized: state.authReducer.initialized,
        isAccountLoading: state.accountReducer.loading,
        isAccountInitialized: state.accountReducer.initialized,
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        refreshToken: (): void => {dispatch(refreshToken())},
        loadAccount: (userId: number, onError: () => void): void => {dispatch(loadAccount(userId, onError))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
