import React from "react"
import {useTranslation} from "react-i18next"
import ITrip from "../Interface/Model/ITrip"
import DevicesInfoType from "../Model/Type/DevicesInfoType"
import DeviceInfoType from "../Model/Type/DeviceInfoType"
import DeviceInfoItem from "./DeviceInfoItem"

interface IProps {
    trip: ITrip,
    devices: DevicesInfoType,
}

const DevicesList: React.FC<IProps> = (props: IProps) => {
    const {t} = useTranslation()

    if (!props.devices.length) {
        return null
    }

    return (
        <>
            <h2 className="info__title">{t("tripWithDevice.deviceTitle")}</h2>
            <div className="info__items">
                {
                    props.devices.map((deviceInfo: DeviceInfoType) => {
                        return (
                            <DeviceInfoItem
                                key={`device-${deviceInfo.deviceID}`}
                                deviceInfo={deviceInfo}
                                trip={props.trip}
                            />
                        )
                    })
                }
            </div>
        </>
    )
}

export default DevicesList

