import React, {ChangeEvent, useState} from "react"
import InputError from "./InputError"
import EyeButton from "./EyeButton"

interface IProps {
    className?: string,
    placeholder?: string,
    isPassword?: boolean,
    value?: string,
    onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined,
    hasError?: boolean,
    errorMessage: string,
}

const Input: React.FC<IProps> = (props: IProps) => {
    const [active, setActive] = useState<boolean>(false)

    return (
        <>
            <div className="input-group">
                <input
                    className={props.className || "input"}
                    type={props.isPassword && !active ? "password": "text"}
                    placeholder={props.placeholder || ""}
                    value={props.value || ""}
                    onChange={props.onChange}
                />
            </div>
            {props.isPassword && <EyeButton status={active} onChange={setActive}/>}
            <InputError
                message={props.errorMessage}
                hasError={props.hasError}
            />
        </>
    )
}

export default Input
