import React, {useEffect, useState} from "react"
import IDevice from "../Interface/Model/IDevice"
import {Buffer} from "buffer"
import {Dispatch} from "@reduxjs/toolkit"
import {loadImage} from "../store/actions/trip"
import {connect} from "react-redux"

interface IDispatch {
    loadImage: (
        deviceTypeId: number,
        onSuccess: (imageSource: string) => void,
        onError: (message?: string) => void
    ) => void,
}

type IProps = IDispatch & {
    device: IDevice,
}

const DeviceImage: React.FC<IProps> = (props: IProps) => {
    const [image, setImage] = useState<string | null>(null)
    const [imageLoading, setImageLoading] = useState<boolean>(false)
    const device: IDevice = props.device

    useEffect(() => {
        if (image || !device || imageLoading || !device.deviceTypeId) {
            return
        }
        setImageLoading(true)
        props.loadImage(device.deviceTypeId, (imageData: string) => {
            const imgBase64 = Buffer.from(imageData, 'binary').toString('base64')
            setImage(imgBase64)
            setImageLoading(false)
        }, () => setImage(null))
    }, [image, device, imageLoading])

    if (!image) {
        return null
    }

    return (
        <img
            className="device__img"
            src={'data:image/png;base64,' + image}
            alt={`${props.device.deviceType} - ${props.device.deviceModel}`}
        />
    )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const mapDispatchToProps = (dispatch: Dispatch | any) => {
    return {
        loadImage: (
            deviceTypeId: number,
            onSuccess: (imageSource: string) => void,
            onError: (message?: string) => void
        ) => dispatch(loadImage(deviceTypeId, onSuccess, onError)),
    }
}

export default connect(null, mapDispatchToProps)(DeviceImage)
