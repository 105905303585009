import React from "react"
import {LANGUAGE_STORAGE_NAME} from "../store/actions/auth"
import {useTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import LayoutWithTitle from "../hoc/LayoutWithTitle"
import classNames from "classnames"

const LanguageScreen: React.FC = () => {
    const {t, i18n} = useTranslation()

    const saveLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
        localStorage.setItem(LANGUAGE_STORAGE_NAME, lng)
    }

    return (
        <LayoutWithTitle title={t("account.mainTitle")}>
            <div className="languages">
                <ul className="languages__list">
                    <li className="languages__item">
                        <Link
                            className={classNames("languages__link", {"is-active": i18n.language === 'en'})}
                            to="#"
                            onClick={() => {saveLanguage('en')}}
                        >English</Link>
                    </li>
                    <li className="languages__item">
                        <Link
                            className={classNames("languages__link", {"is-active": i18n.language === 'ar'})}
                            to="#"
                            onClick={() => {saveLanguage('ar')}}
                        >عربي</Link>
                    </li>
                    <li className="languages__item">
                        <Link
                            className={classNames("languages__link", {"is-active": i18n.language === 'ku'})}
                            to="#"
                            onClick={() => {saveLanguage('ku')}}
                        >کوردی</Link>
                    </li>
                </ul>
            </div>
        </LayoutWithTitle>
    )
}

export default LanguageScreen
